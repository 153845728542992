import { VenueTags } from 'btdt-types';

export const TagLabels: Record<VenueTags, string> = {
  [VenueTags.BAR]: 'בר',
  [VenueTags.LGBTQ]: 'להטב"ק',
  [VenueTags.CLUB]: 'מועדון',
  [VenueTags.FOOD]: 'אוכל',
  [VenueTags.CINEMA]: 'בית קולנוע',
  [VenueTags.CAFE]: 'בית קפה',
  [VenueTags.LIVE_SHOWS]: 'הופעות'
}


export const TagLabelsPlural: Partial<Record<VenueTags, string>> = {
  [VenueTags.BAR]: 'ברים',
  [VenueTags.CLUB]: 'מועדונים',
  [VenueTags.CINEMA]: 'בתי קולנוע',
  [VenueTags.CAFE]: 'בתי קפה'
}
