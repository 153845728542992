import './SearchButton.css';

import React from 'react';
import { FaSearch } from 'react-icons/fa';

interface IProps {
  onClick(): void;
}

const SearchButton: React.FC<IProps> = ({ onClick }: IProps) => {
  return (
    <button aria-label="חיפוש לפי שם" onClick={onClick} className="SearchButton">
      <FaSearch color="white" />
    </button>
  )
}

export default SearchButton;
