import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AccountMenu from './AccountMenu';

const AdminArea: React.FC = () => {
  const children = useOutlet();

  const {
    isAuthenticated,
    user,
    isLoading,
  } = useAuth0();

  const canAccess = isAuthenticated && user?.email && ['amir.ozer@gmail.com', 'remicotlv@gmail.com'].includes(user.email);

  if (isLoading) {
    return <div>טוענת...</div>;
  }

  return (
    <>
      {canAccess ? children : <div>פיזית מה אתה עושה פה. סורי כאילו ליטרלי מי אתה</div>}
      <div style={{ position: 'fixed', left: '8px', top: '8px', zIndex: 100 }}>
        <AccountMenu />
      </div>
    </>
  );
}

export default AdminArea;
