import React from 'react';
import { useQuery } from 'react-query';
import { FaSpinner } from 'react-icons/fa';
import CoordinatesEditor from './CoordinatesEditor';
import { IEditedVenue } from './types';
import useApiClient from '../../useApiClient';
import useDebounce from '../../../helpers/useDebounce';
import './LocationEditor.css';

interface IProps {
  value: Pick<IEditedVenue, 'city' | 'address' | 'coordinates'>;
  onChange(newValue: Pick<IEditedVenue, 'address' | 'coordinates'>): void;
  autoFocus?: boolean;
  disabled?: boolean;
}

const LocationEditor = ({ value, onChange, autoFocus, disabled }: IProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isGeocodingPending, setIsGeocodingPending] = React.useState(false);
  const [addressToGeocode, setAddressToGeocode] = React.useState<string | null>(null);
  const { getApiClient } = useApiClient();

  const handleAddressChange = (newAddress: string): void => {
    setIsGeocodingPending(true);
    setAddressToGeocode(newAddress);
    onChange({ ...value, address: newAddress, coordinates: null });
  };

  const debouncedAddressToGeocode = useDebounce(addressToGeocode, 400);

  const { data: geocodingResult } = useQuery(['geocoding', debouncedAddressToGeocode], async () => {
    const apiClient = await getApiClient();
    return apiClient.post<{ result: [number, number] | null }>(
      '/api/geocoding/geocode',
      { address: debouncedAddressToGeocode, city: value.city }
    )
      .then((response) => response.data.result);
  }, { enabled: Boolean(isGeocodingPending && debouncedAddressToGeocode) })

  React.useEffect(() => {
    if (geocodingResult) {
      setIsGeocodingPending(false);
      onChange({ ...value, coordinates: geocodingResult });
    }
  }, [geocodingResult, onChange, value]);

  return (
    <div className="LocationEditor">
      <div className="LocationEditor-address-line">
        <input
          type="text"
          value={value.address}
          onChange={(event) => handleAddressChange(event.target.value)}
          disabled={disabled}
          autoFocus={autoFocus}
          onFocus={() => setIsExpanded(true)}
          onBlur={() => setIsExpanded(false)}
        />
        {isGeocodingPending && <div className="geocoding-spinner"><FaSpinner size={16} /></div>}
      </div>
      {isExpanded && (
        <div className="LocationEditor-coordinates">
          {isGeocodingPending ? 'מחפשת...' : (
            value.coordinates ? (
              <div className="LocationEditor-map">
                <CoordinatesEditor value={value.coordinates} />
              </div>
            ) : 'לא מצאתי'
          )}
        </div>
      )}
    </div>
  );
};

export default LocationEditor;
