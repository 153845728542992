import React from 'react';

export enum NavigationFlows {
  EXPLORE,
  NEARBY,
  SEARCH,
}

export interface INavigationContext {
  flow: NavigationFlows;
  setFlow(newFlow: NavigationFlows): void;
}

// @ts-ignore
export const NavigationContext = React.createContext<INavigationContext>(null);
