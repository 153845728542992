import React from 'react';
import { IVenue } from 'btdt-types';
import TildeToggle from './TildeToggle';
import VenueTagsEditSelect from './VenueTagsEditSelect';
import ModalTextInput from './ModalTextInput';
import LocationEditor from './LocationEditor';
import { IEditedVenue } from './types';

interface IProps {
  venue: IEditedVenue;
  onChange(newVenue: IEditedVenue): void;
  autoFocus: keyof IVenue | null;
  disabled: boolean;
}

const VenueTableRowEditor: React.FC<IProps> = ({ venue, onChange, autoFocus, disabled }: IProps) => {
  const applyChanges = (changes: Partial<IEditedVenue>): void => {
    onChange({ ...venue, ...changes });
  }

  return (
    <>
      <td>
        <input
          type="text"
          value={venue.name}
          onChange={(event) => applyChanges({ name: event.target.value })}
          disabled={disabled}
          autoFocus={autoFocus === 'name'}
          placeholder="בעברית"
        />
      </td>
      <td>
        <input
          type="text"
          value={venue.nameEn}
          onChange={(event) => applyChanges({ nameEn: event.target.value })}
          disabled={disabled}
          dir="ltr"
          autoFocus={autoFocus === 'nameEn'}
          placeholder="In English"
        />
      </td>
      <td>
        <LocationEditor
          value={venue}
          onChange={applyChanges}
          disabled={disabled}
          autoFocus={autoFocus === 'address'}
        />
      </td>
      <td>
        <div className="yearInputs">
          <input
            type="number"
            value={venue.yearOpened}
            onChange={(event) => applyChanges({ yearOpened: parseInt(event.target.value) })}
            disabled={disabled}
            className="yearInput"
            autoFocus={autoFocus === 'yearOpened'}
          />
          <TildeToggle
            value={venue.isYearOpenedEstimate}
            onChange={(isYearOpenedEstimate) => applyChanges({ isYearOpenedEstimate })}
          />
        </div>
      </td>
      <td>
        עד
      </td>
      <td>
        <div className="yearInputs">
          <input
            type="number"
            value={venue.yearClosed || ''}
            onChange={(event) => applyChanges({ yearClosed: event.target.value ? parseInt(event.target.value) : null })}
            disabled={disabled}
            className="yearInput"
            placeholder="היום"
            autoFocus={autoFocus === 'yearClosed'}
          />
          {venue.yearClosed && (
            <TildeToggle
              value={venue.isYearClosedEstimate}
              onChange={(isYearClosedEstimate) => applyChanges({ isYearClosedEstimate })}
            />
          )}
        </div>
      </td>
      <td>
        <VenueTagsEditSelect
          value={venue.tags}
          onChange={(tags) => applyChanges({ tags })}
          autoFocus={autoFocus === 'tags'}
        />
      </td>
      <td>
        <input
          type="text"
          value={venue.description}
          onChange={(event) => applyChanges({ description: event.target.value })}
          disabled={disabled}
          autoFocus={autoFocus === 'description'}
        />
      </td>
      <td>
        <ModalTextInput
          value={venue.owners}
          onChange={(owners) => applyChanges({ owners })}
        />
      </td>
      <td>
        <ModalTextInput
          value={venue.references}
          onChange={(references) => applyChanges({ references })}
        />
      </td>
    </>
  );
};

export default VenueTableRowEditor;
