import { IVenue } from 'btdt-types';

export const formatYearRange = (venue: IVenue): string => {
  let formatted = venue.yearOpened.toString();
  if (venue.isYearOpenedEstimate) {
    formatted += ' (או מוקדם יותר)';
  }
  if (venue.yearClosed) {
    formatted += ' עד ';
    formatted += venue.yearClosed.toString();
    if (venue.isYearClosedEstimate) {
      formatted += ' (או מאוחר יותר)';
    }
  } else {
    formatted += ' עד היום';
  }
  return formatted;
}
