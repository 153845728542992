import React from 'react';
import SidebarHeader from '../../ExploreScreen/common/SidebarHeader/SidebarHeader';
import SidebarBackLink from '../../ExploreScreen/common/SidebarBackLink/SidebarBackLink';

const MobileBackToExploreHeader: React.FC = () => (
  <SidebarHeader standardPadding={false}>
    <SidebarBackLink label="חזרה למפה" to="/" />
  </SidebarHeader>
);

export default MobileBackToExploreHeader;
