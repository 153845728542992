import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

export const DESKTOP_MIN_WIDTH = 720;
export const MOBILE_MAX_WIDTH = 719;

export const useDevices = (): { isMobile: boolean } => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });
  return { isMobile };
}

export const useEnforceMobileOnly = (): void => {
  const { isMobile } = useDevices();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isMobile) {
      navigate('/', { replace: true });
    }
  }, [isMobile, navigate]);
};
