import './MobileNav.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { FaMap, FaMapMarker, FaSearch } from 'react-icons/fa';
import { NavigationContext, NavigationFlows } from '../../../../context';

const MobileNav: React.FC = () => {
  const { flow, setFlow } = React.useContext(NavigationContext);

  return (
    <div className="MobileNav">
      <Link
        to="/"
        className={flow === NavigationFlows.EXPLORE ? 'active' : ''}
        onClick={() => setFlow(NavigationFlows.EXPLORE)}
      >
        <FaMap />
      </Link>
      <Link
        to="/nearby"
        className={flow === NavigationFlows.NEARBY ? 'active' : ''}
        onClick={() => setFlow(NavigationFlows.NEARBY)}
      >
        <FaMapMarker />
      </Link>
      <Link
        to="/search"
        className={flow === NavigationFlows.SEARCH ? 'active' : ''}
        onClick={() => setFlow(NavigationFlows.SEARCH)}
      >
        <FaSearch />
      </Link>
    </div>
  );
};

export default MobileNav;
