import React from 'react';
import useEventListener from '../../../helpers/useEventListener';
import VenueRelations from './VenueRelations/VenueRelations';
import './VenueRelationsModalTrigger.css';
import useVenuesIndex from '../useVenuesIndex';

interface IProps {
  venueId: number;
}

function VenueRelationsModalTrigger({ venueId }: IProps): React.ReactElement | null {
  const { venues: allVenues } = useVenuesIndex();
  const venue = React.useMemo(() => allVenues.find((item) => item.id === venueId), [allVenues, venueId])

  const [isOpen, setIsOpen] = React.useState(false);

  useEventListener('keydown', (event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (isOpen && keyboardEvent.key === 'Escape') {
      setIsOpen(false);
    }
  });

  if (!venue) {
    return null;
  }

  return (
    <>
      <button onClick={() => setIsOpen(true)}>{venue.relatedVenues?.length} קשורים</button>
      {isOpen && (
        <div className="modal-overlay">
          <div className="venue-relations-modal-content">
            <VenueRelations venue={venue} />
            <button type="button" onClick={() => setIsOpen(false)}>סיימתי</button>
          </div>
        </div>
      )}
    </>
  )
}

export default VenueRelationsModalTrigger;
