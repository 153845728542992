import React from 'react';
import './TildeToggle.css';

interface IProps {
  value: boolean;
  onChange(newValue: boolean): void;
}

const TildeToggle: React.FC<IProps> = ({ value, onChange }: IProps) => {
  return (
    <span className={`tildeToggle ${value ? 'active' : ''}`} onClick={() => onChange(!value)}>
      {value ? '~' : '✔'}
    </span>
  );
}

export default TildeToggle;
