import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SiteLogo from '../ExploreScreen/ExploreMapContainer/SiteLogo/SiteLogo';
import './ContentScreen.css';

interface IProps {
  children: React.ReactNode;
  metaTitle: string;
}

const ContentScreen = ({ children, metaTitle }: IProps) => (
  <div className="ContentScreen">
    {/* @ts-ignore */}
    <Helmet title={metaTitle} />
    <div className="ContentScreen-header">
      <Link to="/">
        <SiteLogo />
      </Link>
    </div>
    <div className="ContentScreen-body">
      {children}
    </div>
  </div>
);

export default ContentScreen;
