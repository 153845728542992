import React from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { IVenue } from 'btdt-types';
import config from '../../../config';
import VenueTableRowEditor from './VenueTableRowEditor';
import { IEditedVenue } from './types';
import { validate } from './helpers';

const DEFAULT_VENUE: Omit<IEditedVenue, 'city'> = {
  id: 0,
  name: '',
  nameEn: '',
  description: '',
  yearOpened: new Date().getFullYear(),
  isYearOpenedEstimate: false,
  yearClosed: null,
  isYearClosedEstimate: false,
  address: '',
  coordinates: null,
  tags: [],
  owners: '',
  references: '',
  isDeleted: false,
};

interface IProps {
  initialVenue: Pick<IVenue, 'city'> & Partial<IVenue>;
  onCreated(updatedVenue: IVenue): void;
  autoFocus: keyof IVenue | null;
}

const VenueTableRowCreate: React.FC<IProps> = ({ initialVenue, onCreated, autoFocus }: IProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editedVenue, setEditedVenue] = React.useState<IEditedVenue>({ ...DEFAULT_VENUE, ...initialVenue });

  const { mutate: save, isLoading } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'localhost:3000',
        },
      });

      const apiClient = axios.create({
        baseURL: config.apiBaseUrl,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });

      return apiClient.post<{ result: IVenue }>('/api/venue', editedVenue);
    },
    {
      onSuccess: (data) => {
        onCreated(data.data.result);
      },
    },
  );

  const { isValid, validationErrors } = validate(editedVenue);

  return (
    <tr>
      <VenueTableRowEditor venue={editedVenue} onChange={setEditedVenue} autoFocus={autoFocus} disabled={isLoading} />
      <td>
        <button
          type="button"
          onClick={() => save()} disabled={isLoading || !isValid}
          title={Object.values(validationErrors).join(', ')}
        >
          שמירה
        </button>
      </td>
    </tr>
  );
};

export default VenueTableRowCreate;
