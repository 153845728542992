import React from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import axios from 'axios';
import { IVenue } from 'btdt-types';
import { ISearchParams, IVenuesDataProvider } from './types';
import { filterVenues } from './helpers';
import config from '../../config';

const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
});

const useAllVenuesQuery = (): UseQueryResult<IVenue[]> => {
  return useQuery(
    'all-venues',
    () => apiClient.get<{ result: IVenue[] }>('/api/venue')
      .then((response) => response.data.result),
  );
};

const useSearchVenuesQuery = (filters: Partial<ISearchParams>, alwaysIncludeId?: number | null, options?: { enabled?: boolean }): { isLoading: boolean, venues: IVenue[] } => {
  const effectiveOptions = { enabled: true, ...options };
  const { data: allVenues, isLoading } = useAllVenuesQuery();
  const venues = React.useMemo(
    () => effectiveOptions.enabled ? filterVenues(allVenues || [], filters, alwaysIncludeId) : [],
    [allVenues, filters, alwaysIncludeId, effectiveOptions.enabled]
  );
  return { isLoading, venues };
}

const useGetVenueQuery = (id: number): { isLoading: boolean, venue: IVenue | undefined } => {
  const { data: allVenues, isLoading } = useAllVenuesQuery();
  const venue = allVenues && allVenues.find((venue) => venue.id === id);
  return { isLoading, venue };
}

const useUpdateVenue = () => {
  const queryClient = useQueryClient();
  const { data: allVenues } = useAllVenuesQuery();

  return {
    update: (id: number, updatedVenue: IVenue) => {
      if (!allVenues) {
        console.warn('Venues not loaded');
        return;
      }
      let newAllVenues: IVenue[] = [];
      if (updatedVenue.isDeleted) {
        newAllVenues = allVenues.filter((venue) => venue.id !== id);
      } else {
        newAllVenues = [...allVenues];
        allVenues.forEach((venue, index) => {
          if (venue.id === id) {
            newAllVenues[index] = updatedVenue;
          }
        })
      }
      queryClient.setQueryData('all-venues', newAllVenues);
    }
  }
};

const useCreateVenue = () => {
  const queryClient = useQueryClient();
  const { data: allVenues } = useAllVenuesQuery();

  return {
    create: (createdVenue: IVenue) => {
      if (!allVenues) {
        console.warn('Venues not loaded');
        return;
      }
      const newAllVenues = [...allVenues, createdVenue];
      queryClient.setQueryData('all-venues', newAllVenues);
    }
  }
};

const backendVenuesDataProvider: IVenuesDataProvider = { useSearchVenuesQuery, useGetVenueQuery, useUpdateVenue, useCreateVenue };

export default backendVenuesDataProvider;
