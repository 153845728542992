interface IConfig {
  apiBaseUrl: string;
  sentryDsn?: string;
  enableAnalytics: boolean;
  enableMemoriesCreation: boolean;
  enableMemoriesMap: boolean;
}

const devConfig: IConfig = {
  apiBaseUrl: 'http://localhost:3001/',
  enableAnalytics: false,
  enableMemoriesCreation: true,
  enableMemoriesMap: true,
};

const prodConfig: IConfig = {
  apiBaseUrl: '/',
  sentryDsn: 'https://c3701444035245eeac5ca2e4a0b515bf@o4504714425532416.ingest.sentry.io/4504714429071360',
  enableAnalytics: true,
  enableMemoriesCreation: true,
  enableMemoriesMap: true,
};

const ENVIRONMENT: string = process.env.NODE_ENV;

const config = ENVIRONMENT === 'development' ? devConfig : prodConfig;

export default config;
