import { Cities, IVenue } from 'btdt-types';
import { IVenuesIndexFilters } from './types';
import React from 'react';
import venuesDataProvider from '../../data/venuesDataProvider';

interface IUseVenuesIndexResult {
  filters: IVenuesIndexFilters;
  applyFilters(filters: Partial<IVenuesIndexFilters>): void;
  venues: IVenue[];
  isLoading: boolean;
  handleUpdated(updatedVenue: IVenue): void;
  handleCreated(createdVenue: IVenue): void;
}

const useVenuesIndex = (): IUseVenuesIndexResult => {
  const [filters, setFilters] = React.useState<IVenuesIndexFilters>({ city: Cities.TLV });

  const { venues, isLoading } = venuesDataProvider.useSearchVenuesQuery(filters);
  const { update } = venuesDataProvider.useUpdateVenue();
  const { create } = venuesDataProvider.useCreateVenue();

  const applyFilters = (newFilters: Partial<IVenuesIndexFilters>): void => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  }

  const handleUpdated = (updatedVenue: IVenue) => {
    update(updatedVenue.id, updatedVenue);
  };

  const handleCreated = (createdVenue: IVenue) => {
    create(createdVenue);
  };

  return {
    filters,
    applyFilters,
    venues,
    isLoading,
    handleUpdated,
    handleCreated,
  };
};

export default useVenuesIndex;
