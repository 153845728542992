import { IEditedVenue } from './types';

export const validate = (venue: IEditedVenue): { isValid: boolean; validationErrors: Record<string, string> } => {
  let isValid = true;
  let validationErrors: Record<string, string> = {};

  if (!venue.name) {
    isValid = false;
    validationErrors.name = 'חסר שם';
  }

  if (!venue.address) {
    isValid = false;
    validationErrors.address = 'חסרה כתובת';
  }

  if (!venue.coordinates) {
    isValid = false;
    validationErrors.coordinates = 'חסרות קואורדינטות';
  }

  if (venue.tags.length === 0) {
    isValid = false;
    validationErrors.tags = 'חסרה תגית אחת לפחות';
  }

  return { isValid, validationErrors };
};
