import React from 'react';
import Select from 'react-select';
import { VenueTags } from 'btdt-types';
import { TagLabels } from '../../../consts';

interface IProps {
  value: VenueTags[];
  onChange(newValue: VenueTags[]): void;
  autoFocus?: boolean;
}

const VenueTagsEditSelect: React.FC<IProps> = ({ value, onChange, autoFocus }: IProps) => {
  const options = React.useMemo(
    () => Object.values(VenueTags).map((value) => ({ value, label: TagLabels[value] })),
    []
  );

  return (
    <Select
      options={options}
      value={options.filter((option) => value.includes(option.value))}
      onChange={(selectedOptions) => onChange(selectedOptions.map((option) => option.value))}
      isMulti
      isClearable={false}
      components={{ IndicatorSeparator: () => null }}
      autoFocus={autoFocus}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          background: '#555555',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '4px',
          outline: 'none',
          fontSize: '12px',
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          fontSize: '12px',
          background: 'purple',
          color: 'white',
          borderRadius: '6px',
        }),
        multiValueLabel: (baseStyles) => ({
          ...baseStyles,
          fontSize: '12px',
          color: 'white',
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          fontSize: '12px',
          color: 'white',
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          color: 'white',
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: 2,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          background: '#333333',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          background: state.isFocused ? '#444444' : '#333333',
          color: 'white',
          fontSize: '12px',
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          zoom: 0.75,
        }),
      }}
    />
  );
}

export default VenueTagsEditSelect;
