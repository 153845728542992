import './FiltersBar.css';

import React from 'react';
import { IExploreFilters } from '../../types';
import YearRangeFilter from './YearRangeFilter/YearRangeFilter';
import TagsFilter from './TagsFilter/TagsFilter';

interface IProps {
  filters: IExploreFilters;
  applyFilters(newFilters: Partial<IExploreFilters>): void;
}

const FiltersBar: React.FC<IProps> = (props: IProps) => {
  const { filters, applyFilters } = props;

  return (
    <div className="FiltersBar">
      <div className="FiltersBar-tags-container">
        <span>סינון:</span>
        <TagsFilter value={filters.tags} onChange={(tags) => applyFilters({ tags })} />
      </div>
      <div className="FiltersBar-year-range-container">
        <YearRangeFilter
          value={filters.yearRange}
          onChange={(yearRange) => applyFilters({ yearRange })}
        />
      </div>
    </div>
  );
}

export default FiltersBar;
