import './VenueDetails.css';

import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import venuesDataProvider from '../../../data/venuesDataProvider';
import { ExploreScreenContext } from '../../context';
import VenueTagsRow from '../VenueTagsRow/VenueTagsRow';
import { formatYearRange } from './helpers';
import VenuesInLocation from './VenuesInLocation';
import { getVenueUrl } from '../../../helpers/navigation';
import { Events, trackEvent } from '../../../utils/analytics';
import NewMemoryButton from './NewMemoryButton';
import VenueMemories from './VenueMemories';
import RelatedVenues from './RelatedVenues';

const VenueDetails: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { filters, applyFilters } = React.useContext(ExploreScreenContext);

  const { isLoading, venue } = venuesDataProvider.useGetVenueQuery(parseInt(id || ''));

  React.useEffect(() => {
    if (isLoading) return;

    if (!venue) {
      navigate('/');
      return;
    }

    const properVenuePathname = getVenueUrl(venue);
    if (properVenuePathname !== location.pathname) {
      navigate(properVenuePathname);
    }

    if (venue.city !== filters.city) {
      applyFilters({ city: venue.city });
    }
  }, [isLoading, venue]);

  if (isLoading || !venue) {
    return null;
  }

  const centerMapOnVenue = () => {
    window.dispatchEvent(new CustomEvent<{ coordinates: number[] }>('btdt.centerMap', { detail: { coordinates: venue.coordinates } }));
  };

  return (
    <div className="VenueDetails">
      {/* @ts-ignore */}
      <Helmet title={venue.name || venue.nameEn} />
      <div className="VenueDetails-content">
        <h1>{venue.name || venue.nameEn}</h1>
        <VenueTagsRow tags={venue.tags} />
        <p className="VenueDetails-address" onClick={centerMapOnVenue}>{venue.address}</p>
        <p>{formatYearRange(venue)}</p>
        <p>{venue.description}</p>
        <RelatedVenues venue={venue} />
        <VenuesInLocation venue={venue} />
        <VenueMemories memories={venue.memories} />
        <NewMemoryButton venue={venue} />
        <div className="VenueDetails-contribute">
          מצאת טעות? יש לך מידע נוסף?
          &nbsp;
          <Link
            to="/contribute"
            onClick={() => trackEvent(Events.CLICK_LINK_TO_CONTRIBUTE, { component: 'venue_details_notice' })}
          >
            אפשר לשלוח לנו כאן
          </Link>
        </div>
      </div>

    </div>
  )
};

export default VenueDetails;
