import config from '../config';

export enum Events {
  SEARCH = 'search',
  SEARCH_NOT_FOUND = 'search_not_found',
  CLICK_SEARCH_RESULT = 'search_result_click',
  CLICK_LINK_TO_CONTRIBUTE = 'contribute_link_click',
  CLICK_SAME_LOCATION_TIMELINE = 'same_location_timeline_click',
  CLICK_EXPLORE_LIST_RESULT = 'explore_list_result_click',
  CLICK_EXPLORE_MAP_MARKER = 'explore_map_marker_click',
}

export const trackEvent = (event: Events, extraData?: Record<string, string | number>) => {
  if (!config.enableAnalytics) {
    console.debug(`Analytics: ${event} ${extraData ? JSON.stringify(extraData) : ''}`);
    return;
  }
  try {
    /* @ts-ignore */
    gtag('event', event, extraData);
  } catch (error) {
    console.warn(`Error reporting event: ${error}`);
  }
}
