import React from 'react';
import { useMutation } from 'react-query';
import omit from 'lodash/omit';
import { IVenue } from 'btdt-types';
import { FaTrashAlt } from 'react-icons/fa';
import VenueTableRowEditor from './VenueTableRowEditor';
import { IEditedVenue } from './types';
import { validate } from './helpers';
import useApiClient from '../../useApiClient';
import VenueRelationsModalTrigger from './VenueRelationsModalTrigger';

interface IProps {
  venue: IVenue;
  onUpdated(updatedVenue: IVenue): void;
  autoFocus: keyof IVenue | null;
  onPendingSaveChange(isPendingSave: boolean): void;
}

const VenueTableRowEdit: React.FC<IProps> = ({ venue, onUpdated, autoFocus, onPendingSaveChange }: IProps) => {
  const { getApiClient } = useApiClient();

  const [editedVenue, setEditedVenue] = React.useState<IEditedVenue>(venue);

  const hasPendingChanges = React.useMemo(() => JSON.stringify(omit(editedVenue, 'relatedVenues')) !== JSON.stringify(omit(venue, 'relatedVenues')), [editedVenue, venue]);

  React.useEffect(() => {
    onPendingSaveChange(hasPendingChanges);
  }, [hasPendingChanges]);

  const { mutate: save, isLoading } = useMutation(
    async () => {
      const { relatedVenues, ...editedVenueProperties} = editedVenue;
      const apiClient = await getApiClient();
      await apiClient.put(`/api/venue/${venue.id}`, editedVenueProperties);
    },
    {
      onSuccess: () => {
        onUpdated(editedVenue as IVenue);
      },
    },
  );

  const requestDelete = (): void => {
    // eslint-disable-next-line
    if (confirm(`באמת באמת למחוק את ${venue.name}?`)) {
      setEditedVenue({ ...editedVenue, isDeleted: true });
    }
  };

  React.useEffect(() => {
    if (editedVenue.isDeleted) {
      save();
    }
  }, [editedVenue.isDeleted]);

  const { isValid, validationErrors } = validate(editedVenue);

  return (
    <tr>
      <VenueTableRowEditor venue={editedVenue} onChange={setEditedVenue} autoFocus={autoFocus} disabled={isLoading}/>
      <td>
        <div className="VenueTableRowEdit-actions">
          {hasPendingChanges && (
            <button
              type="button"
              onClick={() => save()}
              disabled={isLoading || !isValid}
              title={Object.values(validationErrors).join(', ')}
            >
              שמירה
            </button>
          )}
          <button type="button" onClick={requestDelete} title="מחיקה">
            <FaTrashAlt/>
          </button>
        </div>
      </td>
      <td>
        <VenueRelationsModalTrigger venueId={venue.id}/>
      </td>
    </tr>
  );
};

export default VenueTableRowEdit;
