import { IVenue } from 'btdt-types';

const haifaData = [
  {"name":"אבו זיד","nameEn":"Abu Zeid","city":"haifa","description":"","address":"העלייה השנייה 52","yearOpened":1990,"isYearOpenedEstimate":false,"yearClosed":null,"isYearClosedEstimate":false,"tags":["food"],"coordinates":[32.8321618,34.9783389]},
  {"name":"אבו יוסף","nameEn":"Abu Yossef","city":"haifa","description":"","address":"א.ל. זיסו 29","yearOpened":1952,"isYearOpenedEstimate":true,"yearClosed":null,"isYearClosedEstimate":false,"tags":["food"],"coordinates":[32.82777420000001,34.9871949]},
  {"name":"אבו יוסף ובניו","nameEn":"Abu Yossef","city":"haifa","description":"","address":"כיכר פריז 1","yearOpened":1966,"isYearOpenedEstimate":false,"yearClosed":null,"isYearClosedEstimate":false,"tags":["food"],"coordinates":[32.8175561,34.999711]},
];
const tlvData = [
  {"name":"קורדרוי","nameEn":"CorD'uroy","city":"tlv","description":"ממשיך דרכו של הריף ראף","address":"אלנבי 99","yearOpened":2011,"isYearOpenedEstimate":false,"yearClosed":2014,"isYearClosedEstimate":false,"tags":["bar"],"coordinates":[32.0646173,34.7726696]},
  {"name":"רוקוקו","nameEn":"Rokoko","city":"tlv","description":"","address":"אלנבי 99","yearOpened":2015,"isYearOpenedEstimate":false,"yearClosed":2018,"isYearClosedEstimate":false,"tags":["bar"],"coordinates":[32.0646173,34.7726696]},
  {"name":"דוראק","nameEn":"Durak","city":"tlv","description":"","address":"אלנבי 99","yearOpened":2019,"isYearOpenedEstimate":false,"yearClosed":2019,"isYearClosedEstimate":false,"tags":["bar"],"coordinates":[32.0646173,34.7726696]},
];

export const mockVenues: IVenue[] = [...haifaData, ...tlvData]
  .map((item, index) => ({ id: index + 100, ...item})) as IVenue[];
