import React from 'react';
import ReactMapGl, { Marker } from 'react-map-gl';

interface IProps {
  value: [number, number];
}

const CoordinatesEditor = ({ value }: IProps) => {
  return (
    <ReactMapGl
      initialViewState={{
        longitude: value[1],
        latitude: value[0],
        zoom: 16,
      }}
      style={{ width: '100%', height: '100%' }}
      mapStyle="mapbox://styles/mapbox/dark-v11"
      mapboxAccessToken="pk.eyJ1IjoiYW1pcm96ZXIiLCJhIjoiY2xiODN3OHp5MDgzdzNvbXRzbHRmNDh6byJ9.z32MJnbhRaPU0qEXgjq7bQ"
    >
      <Marker
        longitude={value[1]}
        latitude={value[0]}
        color={'#ccaa00'}
      />
    </ReactMapGl>
  );
};

export default CoordinatesEditor;
