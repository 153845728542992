import './VenueTagsRow.css';

import React from 'react';
import { VenueTags } from 'btdt-types';
import { TagLabels } from '../../../consts';

interface IProps {
  tags: VenueTags[];
}

const VenueTagsRow: React.FC<IProps> = ({ tags }: IProps) => (
  <div className="VenueTagsRow">
    {tags.map((tag) => (<span key={tag} className="VenueTagsRow-tag">{TagLabels[tag]}</span>))}
  </div>
)

export default VenueTagsRow;
