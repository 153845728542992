import React from 'react';
import { Link } from 'react-router-dom';
import ContentScreen from './ContentScreen/ContentScreen';

const PrivacyPolicyScreen = (): React.ReactElement => (
  <ContentScreen metaTitle="מדיניות פרטיות">
    <h1>מדיניות פרטיות</h1>
    <h2>
    הקדמה
    </h2>
    <p>
    האתר כולל שימוש משתי סוגים – עם או בלי יצירה וכניסה לפרופיל משתמש אישי. אנחנו מחויבים לשמירה על הפרטיות של כלל משתמשי האתר, רשומים או לא, ומדיניות זו מסבירה בפירוט מה המידע שהאתר אוסף עליכם ולאילו שימושים.
    </p>
    <h2>
    איסוף פרטים אישיים
    </h2>
    <p>
    ייתכן שהסוגים הבאים של פרטים אישיים ייאספו, יאוחסנו, ויעשה בהם שימוש בביקורכם באתר:
    </p>
    <p>
    מידע על המחשב שלכם, כולל כתובת ה-IP, מיקום גיאוגרפי, סוג הדפדפן והגרסה שלו, ומערכת ההפעלה;
    </p>
    <p>
    מידע על הביקורים והשימוש שלכם באתר, כולל מקור ההפניה, אורך הביקור, צפיות בעמודים, ונתיבי המעבר שלכם באתר;
    </p>
    <p>
    מידע מזהה שהוזן עם יצירת פרופיל באתר: כתובת מייל, שם משתמש, תמונת פרופיל;
    </p>
    <p>
    מידע שנוצר בזמן השימוש באתר כולל מתי, כמה, ובאילו נסיבות אתם משתמשים בו;
    </p>
    <p>
    מידע שאתם מפרסמים באתר בכוונה לפרסמו באינטרנט, שכולל את שם המשתמש שלכם, תמונות הפרופיל, והתוכן שבחרתם לשתף (גם אם סימנת שאין ברצונך לקבל קרדיט פומבי באתר);
    </p>
    <p>
    כל סוג אחר של פרטים אישיים שאתם שולחים אלינו במייל או בטפסי עדכון האתר השונים;
    </p>
    <p>
    לפני שתחשפו בפנינו את הפרטים האישיים של אדם אחר, עליכם לבקש את הסכמת האדם הזה גם עבור השיתוף וגם עבור העיבוד של אותם הפרטים האישיים לפי מדיניות זו.
    </p>
    <h2>
    שימוש בפרטים האישיים שלכם
    </h2>
    <p>
        בפרטים אישיים שנמסרו לנו דרך האתר ייעשה שימוש אך ורק למטרות שצוינו <Link to="/terms-of-use">בתנאי השימוש</Link>.
    </p>
    <p>
    ייתכן שבהמשך נשתמש בפרטים האישיים שלכם גם למטרות הבאות:
    </p>
    <p>
    התאמה אישית של האתר עבורכם;
    </p>
    <p>
    שליחת התראות בדואר אלקטרוני שביקשתם באופן ספציפי;
    </p>
    <p>
    שליחה של מסרים שיווקיים שקשורים לאתר שנבחרו בקפידה שלדעתנו עשויות לעניין אתכם, במקרים שבהם הסכמתם לכך ספציפית (אתם יכולים ליידע אותנו בכל עת אם אתם כבר לא מעוניינים במיילים מסוג זה);
    </p>
    <p>
    אספקה של מידע סטטיסטי בנוגע למשתמשים שלנו לצד ג' שאינו גוף מסחרי (אבל צד ג' זה לא יוכל לזהות אף משתמש בודד לפי המידע);
    </p>
    <p>
    טיפול בבירורים ותלונות שהוגשו על ידכם או נוגעות אליכם וקשורות לאתר שלנו;
    </p>
    <p>
    לשמור על האתר שלנו מאובטח ולמנוע הונאה;
    </p>
    <p>
    אימות של היענות לתנאי השירות המכתיבים את אופן השימוש באתר;
    </p>
    <p>
    אם תמסרו לנו מידע אישי לפרסום באתר שלנו, נפרסם את המידע במקום ובקונטקסט שבו ביקשתם לפרסם אותו בהתאם לרישיון שתספקו לנו.
    </p>
    <p>
    אנחנו לא נספק את הפרטים האישיים שלכם ללא הסכמתכם לכל צד ג’, למעט פרטים שביקשתם לפרסם בפומבי על גבי האתר ובלבד שצד ג' זה אינו עושה במידע שימוש מסחרי מכל סוג.
    </p>
    <h2>
    חשיפת פרטים אישיים
    </h2>
    <p>
    אנחנו עשויים למסור את הפרטים האישיים שלכם לכל אחד מהעובדים במיזם, במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.
    </p>
    <p>
    אנחנו עשויים למסור את הפרטים האישיים שלכם:
    </p>
    <p>
    לפי מה שנדרש מאתנו על פי חוק;
    </p>
    <p>
    בהקשר של כל הליך משפטי קיים או עתידי;
    </p>
    <p>
    על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי);
    </p>
    <p>
    למעט על פי הכתוב במדיניות זו, אנו לא נספק את הפרטים האישיים שלכם לצד ג’.
    </p>
    <h2>
    העברות נתונים בינלאומיות
    </h2>
    <p>
    ייתכן שמידע שאנחנו אוספים יאוחסן, יעובד, ויועבר בין כל אחת מהמדינות בהן אנו פועלים, על מנת לאפשר לנו להשתמש במידע בהתאם למדיניות זו.
    </p>
    <p>
    ייתכן שמידע שאנחנו אוספים יועבר למדינות הבאות, להן אין חוקי הגנת נתונים התואמים את אלה הפועלים באזור הכלכלי האירופי: ארצות הברית, רוסיה, יפן, סין, והודו.
    </p>
    <p>
    פרטים אישיים שאתם מפרסמים באתר שלנו או מוסרים לפרסום באתר שלנו עשויים להיות זמינים, באמצעות האינטרנט, מסביב לעולם. אנחנו לא יכולים למנוע את השימוש או השימוש לרעה במידע הזה בידי אחרים.
    </p>
    <p>
    אתם מביעים הסכמה להעברות הפרטים האישיים המתוארות בסעיף זה.
    </p>
    <h2>
    שמירת פרטים אישיים
    </h2>
    <p>
    סעיף זה מפרט את תהליך ומדיניות שמירת הנתונים שלנו, על מנת להבטיח שאנחנו נענים לחובות המשפטיות שלנו הנוגעות לשמירה ולמחיקה של פרטים אישיים.
    </p>
    <p>
    פרטים אישיים שאנחנו מעבדים עבור כל מטרה או מטרות, לא יישמרו יותר מכמה שנדרש עבור מטרה או מטרות אלה.
    </p>
    <p>
    אנו נשמור מסמכים (כולל מסמכים אלקטרוניים) המכילים נתונים אישיים:
    </p>
    <p>
    לפי מה שנדרש מאתנו על פי חוק;
    </p>
    <p>
    אם לדעתנו המסמכים עשויים להיות רלוונטיים לכל הליך משפטי מתמשך או פוטנציאלי;
    </p>
    <p>
    על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי).
    </p>
    <h2>
    אבטחת הפרטים האישיים שלכם
    </h2>
    <p>
    אנחנו ננקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את האבדן, השימוש לרעה, או השינוי של הפרטים האישיים שלכם.
    </p>
    <p>
    אנחנו נאחסן את כל הפרטים האישיים שאתם מספקים בשרתים מאובטחים (המוגנים בסיסמא ובחומת אש).
    </p>
    <p>
    אתם מכירים בכך שהשידור של מידע על פני האינטרנט אינו מאובטח מעצם היותו, ואנחנו לא יכולים להבטיח שנתונים שנשלחים דרך האינטרנט יהיו מאובטחים.
    </p>
    <p>
    אתם אחראים על שמירת הסיסמא בה אתם משתמשים לקבלת גישה לאתר שלנו חסויה; אנחנו לא נבקש מכם את הסיסמא שלכם (למעט בזמן ההתחברות לאתר שלנו).
    </p>
    <h2>
    תיקונים
    </h2>
    <p>
    אנחנו עשויים לעדכן מדיניות זו מעת לעת באמצעות פרסום של גרסה חדשה באתר שלנו. עליכם לבדוק את העמוד הזה מדי פעם על מנת לוודא שאתם מבינים כל שינוי שנעשה במדיניות זו. במקרים של שינויים מהותיים במדיניות משתמשים רשומים יקבלו על כך עדכון בהודעת דואר אלקטרוני.
    </p>
    <h2>
    הזכויות שלכם
    </h2>
    <p>
    אתם יכולים להורות לנו לספק לכם כל פרט מפרטיכם האישיים שאנו מחזיקים הנוגעים אליכם; אספקת פרטים אלה תהיה כפופה להזדהות בתנאים מחמירים תוך אספקת ראיות סבירות שנבקש על מנת לאשר זהותכם.
    </p>
    <p>
    אנחנו עשויים לסרב למסור פרטים אישיים שאתם מבקשים עד למידה המורשית על פי חוק.
    </p>
    <h2>
    אתרים צד ג’
    </h2>
    <p>
    האתר שלנו כולל קישורים חיצוניים ופרטים על אתרים צד ג’. אין לנו שליטה על, ואיננו אחראים על, מדיניות הפרטיות והנהלים של כל צד ג'.
    </p>
    <h2>
    קוקיז (עוגיות)
    </h2>
    <p>
    האתר שלנו משתמש בעוגיות. עוגייה היא קובץ המכיל מזהה (מחרוזת של אותיות ומספרים) שנשלח על ידי שרת אינטרנט לדפדפן אינטרנט ומאוחסן בדפדפן. אז, המזהה נשלח בחזרה לשרת בכל פעם שהדפדפן מבקש מהשרת להציג דף אינטרנט. עוגיות יכולות להיות או עוגיות “עיקשות” או עוגיות “פעולה” (session): עוגייה עיקשת תאוחסן בדפדפן שלכם ותישאר בתוקף עד תאריך התפוגה שנקבע לה, אלא אם תימחק על ידי המשתמש לפני תאריך התפוגה; עוגיית פעולה, מאידך, תפוג בסוף זמן הגלישה הנוכחי שלכם, כשתסגרו את הדפדפן. בדרך כלל עוגיות לא כוללות מידע שמזהה משתמשים אישית, אבל פרטים אישיים שאנחנו מאחסנים הנוגעים עליכם יכולים להיות מקושרים למידע המאוחסן והמתקבל מתוך עוגיות. אנחנו משתמשים גם בעוגיות פעולה וגם בעוגיות עיקשות באתר שלנו.
    </p>
    <p>
    השמות של העוגיות בהן אנחנו משתמשים באתר שלנו, והמטרות לשמן הן בשימוש, מפורטות מטה:
    </p>
    <p>
    אנחנו משתמשים בשירותי Google Analytics באתר שלנו כדי לזהות מחשב כשמשתמש מבקר באתר, לעקוב אחרי משתמשים בזמן השימוש שלהם, לנתח את השימוש באתר, למנוע הונאה ולשפר את האבטחה של האתר;
    </p>
    <p>
    רוב הדפדפנים מאפשרים לכם לסרב או להגביל שימוש בעוגיות. על מנת לשלוט בכך עליכם להיכנס להגדרות הפרטיות ו\או האבטחה בדפדפן שלכם.
    </p>
    <p>
    לחסימה מוחלטת של כל העוגיות תהיה השפעה שלילית על נוחות השימוש באתרים רבים. אם תחסמו עוגיות, ייתכן שחלק מהאפשרויות באתר שלנו לא יפעלו עבורכם באופן תקין.
    </p>
    <p>
    אם ברצונכם למחוק עוגיות שכבר מאוחסנות במחשב שלכם תוכלו לעשות זאת ידנית או בעזרת ניקוי כולל של נתוני הגלישה בדפדפן במסך ההגדרות המתקדמות.
    </p>
    <p>
    למחיקה של עוגיות תהיה השפעה שלילית על נוחות השימוש באתרים רבים.
    </p>
    <p><i>
    מסמך זה נוצר בעזרת תבנית של SEQ Legal (seqlegal.com) ששונתה על ידי Website Planet (www.websiteplanet.com)
    </i></p>
  </ContentScreen>
);

export default PrivacyPolicyScreen;
