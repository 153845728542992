import Fuse from 'fuse.js'
import { Cities, IVenue, VenueTags } from 'btdt-types';
import { ISearchParams } from './types';

// let cityFuses: Record<Cities, Fuse<IVenue> | undefined> = {
//   [Cities.TLV]: undefined,
//   [Cities.HAIFA]: undefined,
// };

const getCityFuse = (venues: IVenue[], city: Cities): Fuse<IVenue> => {
  const cityVenues = filterVenues(venues, { city });
  return new Fuse(cityVenues, { keys: ['name', 'nameEn'], includeScore: true, threshold: 0.3 });
  // disable caching for now
  // if (!cityFuses[city]) {
  //   const cityVenues = filterVenues(venues, { city });
  //   cityFuses[city] = new Fuse(cityVenues, { keys: ['name', 'nameEn'], includeScore: true, threshold: 0.3 });
  // }
  // return cityFuses[city] as Fuse<IVenue>;
}

const searchByName = (venues: IVenue[], city: Cities, searchQuery: string): IVenue[] => {
  const fuse = getCityFuse(venues, city)
  const results = fuse.search(searchQuery);
  return results.map((result) => result.item);
};

export const filterVenues = (venues: IVenue[], filters: Partial<ISearchParams>, alwaysIncludeId?: number | null): IVenue[] => {
  if (Object.keys(filters).length === 0) {
    return venues;
  }

  if (filters.name && filters.city) {
    const { name, city, ...otherFilters } = filters;
    const venuesByName = searchByName(venues, city, name);
    if (Object.keys(otherFilters).length === 0) {
      return venuesByName;
    }
    return filterVenues(venuesByName, { city, ...otherFilters }, alwaysIncludeId);
  }

  return venues.filter((venue) => {
    return (
        (!filters.name || venue.name.toLowerCase().includes(filters.name.toLowerCase()) || venue.nameEn?.toLowerCase().includes(filters.name.toLowerCase())) &&
        (!filters.address || venue.address.toLowerCase().includes(filters.address.toLowerCase())) &&
        (!filters.yearRange || venue.yearOpened <= filters.yearRange.to) &&
        (!filters.yearRange || !venue.yearClosed || venue.yearClosed >= filters.yearRange.from) &&
        (!Array.isArray(filters.tags) || filters.tags.length === 0 || venue.tags.some((tag) => (filters.tags as VenueTags[]).includes(tag))) &&
        venue.city === filters.city &&
        (!filters.coordinates || (
          Math.abs(filters.coordinates[0] - venue.coordinates[0]) < 0.0000001 &&
          Math.abs(filters.coordinates[1] - venue.coordinates[1]) < 0.0000001
        )) &&
        (!filters.bounds || (
          venue.coordinates[1] >= filters.bounds.getWest() &&
          venue.coordinates[1] <= filters.bounds.getEast() &&
          venue.coordinates[0] >= filters.bounds.getSouth() &&
          venue.coordinates[0] <= filters.bounds.getNorth()
        ))
      ) ||
      (alwaysIncludeId && venue.id === alwaysIncludeId);
  });
}
