import React from 'react';
import { IVenue } from 'btdt-types';
import VenueTagsRow from '../../../ExploreScreen/common/VenueTagsRow/VenueTagsRow';
import VenueTableRowEdit from './VenueTableRowEdit';
import VenueRelationsModalTrigger from './VenueRelationsModalTrigger';

interface IProps {
  venue: IVenue;
  onUpdated(updatedVenue: IVenue): void;
  onSelected(): void;
  isMainSelected: boolean;
}

const VenueTableRowView: React.FC<IProps> = ({ venue, onUpdated, onSelected, isMainSelected }: IProps) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [isPendingSave, setIsPendingSave] = React.useState(false);
  const [editTriggerField, setEditTriggerField] = React.useState<keyof IVenue | null>(null);

  const handleUpdated = (updatedVenue: IVenue): void => {
    onUpdated(updatedVenue);
    setIsEditing(false);
  };

  React.useEffect(() => {
    if (isEditing && !isMainSelected && !isPendingSave) {
      setIsEditing(false);
    }
  }, [isEditing, isMainSelected, isPendingSave]);

  if (isEditing) {
    return (
      <VenueTableRowEdit venue={venue} onUpdated={handleUpdated} autoFocus={editTriggerField} onPendingSaveChange={setIsPendingSave} />
    );
  }

  const handleRowClick = (event: Pick<MouseEvent, 'target'>) => {
    // @ts-ignore
    if ([event.target?.className, event.target?.parentNode?.className].includes('ignore-row-click')) {
      return;
    }

    setIsEditing(true);
    onSelected();
  }

  return (
    <tr onClick={handleRowClick}>
      <td onClick={() => setEditTriggerField('name')}>
        {venue.name}
      </td>
      <td dir="ltr" onClick={() => setEditTriggerField('nameEn')}>
        {venue.nameEn}
      </td>
      <td onClick={() => setEditTriggerField('address')}>
        {venue.address}
      </td>
      <td onClick={() => setEditTriggerField('yearOpened')}>
        {venue.yearOpened}
        &nbsp;
        {venue.isYearOpenedEstimate && '~'}
      </td>
      <td>
        עד
      </td>
      <td onClick={() => setEditTriggerField('yearClosed')}>
        {venue.yearClosed || 'היום'}
        &nbsp;
        {venue.isYearClosedEstimate && '~'}
      </td>
      <td onClick={() => setEditTriggerField('tags')}>
        <VenueTagsRow tags={venue.tags}/>
      </td>
      <td onClick={() => setEditTriggerField('description')}>
        {venue.description}
      </td>
      <td>
        <div className="long-text-preview">
          {venue.owners}
        </div>
      </td>
      <td>
        <div className="long-text-preview">
          {venue.references}
        </div>
      </td>
      <td/>
      <td className="ignore-row-click">
        <VenueRelationsModalTrigger venueId={venue.id}/>
      </td>
    </tr>
  );
};

export default VenueTableRowView;
