import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import './AccountMenu.css';

const AccountMenu: React.FC = () => {
  const {
    loginWithPopup,
    logout,
    user,
    isLoading,
  } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!user) {
    return <button type="button" onClick={() => loginWithPopup({ authorizationParams: { audience: 'localhost:3000' } })}>כניסה</button>
  }

  return (
    <div className="AccountMenu">
      <FaUser color="white" />
      <div>{user.email}</div>
      <button type="button" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>יציאה</button>
    </div>
  );
}

export default AccountMenu;
