import React from 'react';
import useEventListener from '../../../helpers/useEventListener';
import './ModlaTextInput.css';

interface IProps {
  value: string;
  onChange(newValue: string): void;
}

const ModalTextInput = ({ value, onChange }: IProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  useEventListener('keydown', (event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (isOpen && keyboardEvent.key === 'Escape') {
      setIsOpen(false);
    }
  });

  return (
    <>
      <div className="modal-text-input" onClick={() => setIsOpen(true)}>{value}</div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-text-input-expanded">
            <textarea
              value={value}
              onChange={(event) => onChange(event.target.value)}
              autoFocus
              rows={20}
            />
            <button type="button" onClick={() => setIsOpen(false)}>סיימתי</button>
          </div>
        </div>
      )}
    </>
  )
};

export default ModalTextInput;
