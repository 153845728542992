import './MobileLayout.css';

import React from 'react';
import MobileNav from './MobileNav/MobileNav';

interface IProps {
  children: React.ReactNode;
}

const MobileLayout: React.FC<IProps> = ({ children }: IProps) => {
  return (
    <div className="MobileLayout">
      {children}
      <MobileNav />
    </div>
  );
};

export default MobileLayout;
