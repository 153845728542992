import React from 'react';
import useVenuesIndex from './useVenuesIndex';
import VenuesTable from './VenuesTable/VenuesTable';
import './VenuesIndexScreen.css';
import CityFilter from '../../ExploreScreen/CityFilter/CityFilter';

const VenuesIndexScreen: React.FC = () => {
  const { venues, isLoading, filters, applyFilters, handleUpdated, handleCreated } = useVenuesIndex();

  if (isLoading) {
    return <div>טוענת...</div>;
  }

  return (
    <>
      <div className="venuesIndexScreen-header">
        <h1>ניהול מקומות</h1>
        <CityFilter value={filters.city} onChange={(newCity) => applyFilters({ city: newCity })} />
      </div>
      <VenuesTable
        venues={venues}
        filters={filters}
        applyFilters={applyFilters}
        onUpdated={handleUpdated}
        onCreated={handleCreated}
      />
    </>
  );
};

export default VenuesIndexScreen;
