
import React from 'react';
import type { FeatureCollection } from 'geojson';
import { CircleLayer, Layer, Source } from 'react-map-gl';
import { IVenue } from 'btdt-types';

interface IProps {
  venues: IVenue[];
}

const layerStyle: CircleLayer = {
  id: 'point',
  type: 'circle',
  paint: {
    'circle-radius': 30,
    'circle-color': '#ffdd00',
    'circle-opacity': {
      property: 'memoriesCount',
      stops: [
        [1, 0.1],
        [2, 0.15],
        [3, 0.2],
        [4, 0.25],
      ]
    }
  }
};

function MemoriesGlowLayer({ venues }: IProps): React.ReactElement {

  const geojson: FeatureCollection = React.useMemo(()=> ({
    type: 'FeatureCollection',
    features: venues
      .filter((venue) => venue.memories?.length)
      .map((venue) => ({
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [venue.coordinates[1], venue.coordinates[0]] },
        properties: { memoriesCount: venue.memories?.length || 0 },
      })),
  }), [venues]);

  return (
    <Source id="my-data" type="geojson" data={geojson}>
      <Layer {...layerStyle} />
    </Source>
  );
}

export default MemoriesGlowLayer;
