import React from 'react';
import { useGeolocated } from 'react-geolocated';
import sortBy from 'lodash/sortBy';
// @ts-ignore
import geodist from 'geodist';
import { useQuery } from 'react-query';
import { IVenue } from 'btdt-types';
import venuesDataProvider from '../../../data/venuesDataProvider';
import ResultsList from '../../common/ResultsList/ResultsList';
import { NavigationContext, NavigationFlows } from '../../../context';
import { useEnforceMobileOnly } from '../../../helpers/devices';
import { ExploreScreenContext } from '../../context';

const NearbyList: React.FC = () => {
  const { setFlow } = React.useContext(NavigationContext);
  const { filters: { city } } = React.useContext(ExploreScreenContext);
  useEnforceMobileOnly();
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated();
  const { isLoading, venues: allVenues } = venuesDataProvider.useSearchVenuesQuery({ city });

  React.useEffect(() => {
    setFlow(NavigationFlows.NEARBY);
  }, []);

  const { data: nearbyVenues } = useQuery(
    ['nearby', coords],
    async (): Promise<(IVenue & { distance: number })[]> => {
      if (!coords) {
        return [];
      }
      const venuesWithDistance = allVenues.map((venue) => ({
        ...venue,
        distance: geodist(
          { lat: coords.latitude, lon: coords.longitude },
          { lat: venue.coordinates[0], lon: venue.coordinates[1] },
          { unit: 'meters' },
        ),
      }));
      const sortedVenues = sortBy(venuesWithDistance, (venue) => venue.distance);
      return sortedVenues.slice(0, 30);
    },
    { enabled: !!coords && !isLoading },
  );

  if (!isGeolocationAvailable) {
    return <div>אין נתונים על המיקום הנוכחי שלך</div>
  }

  if (!isGeolocationEnabled) {
    return (
      <div style={{ padding: '16px '}}>
        <h4>בקרוב</h4>
      </div>
    );
    // return <div>אין לנו גישה לנתונים על המיקום שלך</div>
  }

  return (
    <div>
      {nearbyVenues ? <ResultsList venues={nearbyVenues} /> : <div style={{ padding: '16px' }}>מחפשות סביבך...</div>}
    </div>
  );
};

export default NearbyList;
