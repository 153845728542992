import axios, { AxiosInstance } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../config';

const useApiClient = (): { getApiClient(): Promise<AxiosInstance> } => {
  const { getAccessTokenSilently } = useAuth0();

  const getApiClient = async (): Promise<AxiosInstance> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'localhost:3000',
      },
    });
    return axios.create({
      baseURL: config.apiBaseUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
  };

  return { getApiClient };
};

export default useApiClient;
