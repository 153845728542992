import React from 'react';
import SidebarHeader from '../common/SidebarHeader/SidebarHeader';
import SidebarBackLink from '../common/SidebarBackLink/SidebarBackLink';

const BackToExploreHeader: React.FC = () => (
  <SidebarHeader standardPadding={false}>
    <SidebarBackLink label="חזרה לרשימה" to="/" />
  </SidebarHeader>
);

export default BackToExploreHeader;
