import React from 'react';
import SidebarHeader from '../common/SidebarHeader/SidebarHeader';
import SidebarBackLink from '../common/SidebarBackLink/SidebarBackLink';

interface IProps {
  showBack: boolean;
}

const NearbyHeader: React.FC<IProps> = ({ showBack }: IProps) => (
  <SidebarHeader standardPadding={!showBack}>
    {showBack ? <SidebarBackLink label="מקומות סביבי" to="/nearby" /> : <h2>מקומות סביבי</h2>}
  </SidebarHeader>
);

export default NearbyHeader;
