import './SidebarBackLink.css';

import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface IProps {
  label: string;
  to: string;
}

const SidebarBackLink: React.FC<IProps> = ({ label, to }: IProps) => (
  <Link to={to} className="SidebarBackLink"><FaChevronRight /><span>{label}</span></Link>
);

export default SidebarBackLink;
