import React from 'react';
import Modal from '@mui/joy/Modal';
import Button from '@mui/joy/Button';
import ModalClose from '@mui/joy/ModalClose';
import { IVenue } from 'btdt-types';
import NewMemoryForm from '../../../NewMemoryForm/NewMemoryForm';
import config from '../../../config';
import { useDevices } from '../../../helpers/devices';

interface IProps {
  venue: IVenue;
}

function NewMemoryButton({ venue }: IProps): React.ReactElement | null {
  const { isMobile } = useDevices();

  const [isOpen, setIsOpen] = React.useState(false);

  if (!config.enableMemoriesCreation) {
    return null;
  }

  return (
    <>
      <Button type="button" onClick={() => setIsOpen(true)} size="sm">יש לי זיכרון מכאן</Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: isMobile ? 'flex-start' : 'center', marginTop: isMobile ? '24px' : 0 }}
      >
        <div style={{ position: 'relative' }}>
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              left: 'calc(-1/4 * var(--IconButton-size))',
              right: 'auto',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'background.surface',
            }}
          />
          <NewMemoryForm venue={venue} onDone={() => setIsOpen(false)} />
        </div>
      </Modal>
    </>
  )
}

export default NewMemoryButton;
