import React from 'react';
import Select from 'react-select';
import useVenuesIndex from '../../useVenuesIndex';

interface IProps {
  onChange(venueId: number): void;
  excludeOptions: number[];
}

function VenueSelect({ onChange, excludeOptions }: IProps): React.ReactElement {
  const { venues } = useVenuesIndex();

  const options = React.useMemo(
    () => venues
      .filter((venue) => !excludeOptions.includes(venue.id))
      .map((venue) => ({ value: venue.id, label: `${venue.name || venue.nameEn} (${venue.address})` })),
    [venues, excludeOptions]
  );

  return (
    <Select
      options={options}
      value={null}
      onChange={(selectedOption) => onChange(selectedOption?.value || 0)}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          background: '#555555',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '4px',
          outline: 'none',
          fontSize: '12px',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          fontSize: '14px',
          color: 'white',
          borderRadius: '6px',
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          color: 'white',
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: 2,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          background: '#333333',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          background: state.isFocused ? '#444444' : '#333333',
          color: 'white',
          fontSize: '12px',
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          zoom: 0.75,
        }),
      }}
    />
  )
}

export default VenueSelect;
