import './CityFilter.css';

import React from 'react';
import Select from 'react-select';
import { Cities } from 'btdt-types';

interface IProps {
  value: Cities;
  onChange(newCity: Cities): void;
}

const OPTIONS = [
  { value: Cities.TLV, label: 'תל אביב-יפו' },
  { value: Cities.HAIFA, label: 'חיפה' },
];

const CityFilter: React.FC<IProps> = ({ value, onChange }: IProps) => {
  return (
    <div className="CityFilter">
      <Select
        id="city"
        name="city"
        options={OPTIONS}
        classNamePrefix="CityFilter-select"
        value={OPTIONS.find((option) => option.value === value)}
        onChange={(option) => onChange(option?.value as Cities)}
        isSearchable={false}
        components={{ IndicatorSeparator: () => null }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            background: 'rgba(80, 80, 80, 0.8)',
            border: 'none',
            borderColor: 'transparent',
            borderRadius: '8px',
            outline: 'none',
            minHeight: '36px',
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
            // left: 'auto'
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            // display: 'block',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            background: '#333333',
            width: '110px',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            background: state.isFocused ? '#444444' : '#333333',
            color: 'white',
          })
        }}
      />
    </div>
  );
};

export default CityFilter;
