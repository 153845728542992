import React from 'react';
import { NavigationContext, NavigationFlows } from '../../context';
import { useEnforceMobileOnly } from '../../helpers/devices';

const MobileSearchScreen: React.FC = () => {
  const { setFlow } = React.useContext(NavigationContext);
  useEnforceMobileOnly();

  React.useEffect(() => {
    setFlow(NavigationFlows.SEARCH);
  }, []);

  return (
    <div />
  );
};

export default MobileSearchScreen;
