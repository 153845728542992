import { IMemory } from 'btdt-types';
import React from 'react';
import MemorySummary from '../MemorySummary/MemorySummary';

interface IProps {
  memories: IMemory[] | undefined;
}

function VenueMemories({ memories }: IProps): React.ReactElement | null {
  if (!memories?.length) {
    return null;
  }

  return (
    <>
      <h2>זיכרונות</h2>
      <ul className="VenueDetails-venue-memories">
        {memories.map((memory) => (
          <li key={memory.id}>
            <MemorySummary memory={memory} />
          </li>
        ))}
      </ul>
    </>
  )
}

export default VenueMemories;
