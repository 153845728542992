import React from 'react';
import { Link } from 'react-router-dom';
import { IVenue } from 'btdt-types';
import { formatYearRange } from './helpers';
import { getVenueUrl } from '../../../helpers/navigation';
import { Events, trackEvent } from '../../../utils/analytics';

interface IProps {
  venues: IVenue[];
  currentVenueId: number;
  showAddress?: boolean;
}

const VenuesTimeline: React.FC<IProps> = ({ venues, currentVenueId, showAddress }: IProps) => (
  <ul>
    {venues.sort((venue1, venue2) => venue1.yearOpened - venue2.yearOpened).map((venue) => {
      const displayName = venue.name || venue.nameEn;
      const nameElement = venue.id === currentVenueId ? <>{displayName}</> :
        (
          <Link
            to={getVenueUrl(venue)}
            onClick={() => trackEvent(Events.CLICK_SAME_LOCATION_TIMELINE)}
          >
            {displayName}
          </Link>
        );
      return (
        <li key={venue.id}>
          {nameElement}<br/><small>{formatYearRange(venue)}{showAddress && `, ${venue.address}`}</small>
        </li>
      );
    })}
  </ul>
);

export default VenuesTimeline;
