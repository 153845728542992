import './ResultsListItem.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { IVenue } from 'btdt-types';
import VenueTagsRow from '../VenueTagsRow/VenueTagsRow';
import { getVenueUrl } from '../../../helpers/navigation';
import { Events, trackEvent } from '../../../utils/analytics';
import MemorySummary from '../MemorySummary/MemorySummary';

interface IProps {
  venue: IVenue & { distance?: number };
}

const ResultsListItem: React.FC<IProps> = ({ venue }: IProps) => (
  <Link
    to={getVenueUrl(venue)}
    className="block-link"
    onClick={() => trackEvent(Events.CLICK_EXPLORE_LIST_RESULT)}
  >
    <div className="ResultsListItem">
      <h3>{venue.name || venue.nameEn}</h3>
      <div className="ResultsListItem-tags">
        <VenueTagsRow tags={venue.tags}/>
      </div>
      <small className="ResultsListItem-address">{venue.address} {venue.distance && `(${venue.distance} מ')`}</small>
      {venue.memories?.length ? (
        <div className="ResultsListItem-memories">
          <MemorySummary memory={venue.memories[0]} />
          {venue.memories.length > 1 && (<small>{venue.memories.length === 2 ? 'ועוד זיכרון אחד' : `ועוד ${venue.memories.length} זיכרונות`}</small>)}
        </div>
      ) : null}
    </div>
  </Link>
);

export default ResultsListItem;
