"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueTags = exports.Cities = void 0;
var Cities;
(function (Cities) {
    Cities["TLV"] = "tlv";
    Cities["HAIFA"] = "haifa";
})(Cities = exports.Cities || (exports.Cities = {}));
var VenueTags;
(function (VenueTags) {
    VenueTags["BAR"] = "bar";
    VenueTags["CAFE"] = "cafe";
    VenueTags["CLUB"] = "club";
    VenueTags["FOOD"] = "food";
    VenueTags["LGBTQ"] = "lgbtq";
    VenueTags["CINEMA"] = "cinema";
    VenueTags["LIVE_SHOWS"] = "live_shows";
})(VenueTags = exports.VenueTags || (exports.VenueTags = {}));
