import './SidebarHeader.css';

import React from 'react';

interface IProps {
  children: React.ReactNode;
  standardPadding?: boolean;
}

const SidebarHeader: React.FC<IProps> = ({ children, standardPadding = true }: IProps) => (
  <div className={`SidebarHeader ${standardPadding ? 'SearchHeader-standard-padding' : ''}`}>
    {children}
  </div>
);

export default SidebarHeader;
