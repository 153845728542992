import React from 'react';
import { Link } from 'react-router-dom';
import ContentScreen from './ContentScreen/ContentScreen';
import { Events, trackEvent } from './utils/analytics';

const AboutScreen = () => {
  const credit = React.useMemo(() => new Date().getMinutes() % 2 === 0 ? 'אמיר ורעות' : 'רעות ואמיר', []);

  return (
    <ContentScreen metaTitle="אודות">
      <h1>אודות</h1>
      <p>
        ברוכות.ים הבאות.ים למפת הזמן – פרויקט אינטראקטיבי שסוקר את בתי הבילויים והתרבות בתל&nbsp;אביב-יפו
        ובחיפה לאורך ההיסטוריה.
      </p>
      <p>
        תמצאו כאן אינדקס ממופה של ברים, מועדונים, מסעדות, בתי קולנוע ובתי קפה שהיו קיימים בשתי הערים לאורך השנים עם
        מיקומיהם ומידע נוסף שנראה לנו חשוב שיישמר אודותם.
        הרבה מהמידע הזה לא היה זמין בקלות באינטרנט עד עכשיו, עובדה בלתי נתפסת בעינינו.
      </p>
      <p>
        הפרויקט נוצר למען הציבור והוא ללא מטרת רווח.
        <br/>
        זוהי המחווה האוהבת שלנו למקומות ולאנשים שעשו את הערים שבהן גדלנו.
      </p>
      <p>
        <i>3&gt;&nbsp;{credit}</i>
      </p>

      <h3>יש לי מה להוסיף או לתקן!</h3>
      <p>
        מעולה, נשמח לקבל כל מידע נוסף שתרצו לתרום לנו.
        <br/>
        <Link
          to="/contribute"
          onClick={() => trackEvent(Events.CLICK_LINK_TO_CONTRIBUTE, { component: 'about_screen' })}
        >
          אפשר ליצור איתנו קשר באמצעות הטופס כאן.
        </Link>
        <br/>
        נעדכן את המידע באתר בכפוף ליכולת לאמת אותו ברמה מספקת מבחינתנו, אז כל לינק לסימוכין יתקבל באהבה.
      </p>

      <h3>למה התאריכים כתובים ככה?</h3>
      <p>יש הרבה מקומות שאנחנו יודעות על שנים שבהן הם היו קיימים, אבל לא מצאנו בוודאות את שנת הפתיחה או הסגירה שלהם.
        היית שם בשנים אחרות? נשמח לדעת!</p>

      <h3>למה יש הרבה מקומות באותה כתובת באותה שנה?</h3>
      <p>הרבה עסקים פועלים מאותה כתובת במיקומים שונים, אבל המפה כרגע מציגה נקודה קבועה לכל כתובת. בהמשך נעבוד על הזזת
        סימונים מדוייקים בתוך המבנה.</p>

      <h3>מה מתוכנן להמשך?</h3>
      <p>
        כמו שאפשר לראות, האתר כרגע בגרסת בטא מוגבלת. מעבר לעוד המון מידע שנצטרך להכניס ולעדכן בעזרתכן, התכנון הוא
        להוסיף שדות מעניינים נוספים לכל מקום.
        <br/>
        מעבר למידע טכני כמו מי היו הבעלים ולינקים מעניינים על מקומות, נוסיף גם אפשרות לשלוח תמונות ונרצה לשמוע את
        הסיפורים והחוויות שלכן מהמקומות שהייתם בהם.
      </p>
      <h3>תודה</h3>
      <p>תודה לכל החברים שעזרו לנו בתרומת מידע בשלבים הראשונים.<br/>
        לדני חיוטין על בתי הקולנוע, לנועה ליברמן פלשקעס על המועדונים מתחילת המילניום, לאור רוזנשטיין ואסף רזון על המסעדות ולנדב אפל וטופי סטולר שחלקו איתנו מעל עשור של צ'ק אינים בפורסקוור 🖤<br/>
        מידע רב במאגר נמצא בזכות המחקר המקיף של בתיה כרמיאל בספרה "בתי הקפה של תל-אביב, <span style={{ whiteSpace: 'nowrap' }}>1980-1920</span>" וספרו של ניסן שור "לרקוד עם דמעות בעיניים" על ההיסטוריה של תרבות המועדונים בישראל.</p>
      <p>
        <Link to="/terms-of-use">תנאי שימוש</Link>{' | '}
        <Link to="/privacy-policy">מדיניות פרטיות</Link>
      </p>
    </ContentScreen>
  )
};

export default AboutScreen;
