import 'mapbox-gl/dist/mapbox-gl.css';

import React from 'react';
import ReactMapGl, { Marker, ViewStateChangeEvent } from 'react-map-gl';
// @ts-ignore
// eslint-disable-next-line
import mapboxgl, { LngLatBounds, MapboxEvent } from '!mapbox-gl';
// @ts-ignore
// import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { Cities, IVenue } from 'btdt-types';
import useEventListener from '../../../helpers/useEventListener';
import config from '../../../config';
import MemoriesGlowLayer from './MemoriesGlowLayer';

interface IProps {
  city: Cities;
  venues: IVenue[];
  onViewBoundsChange(bounds: LngLatBounds): void;
  onVenueClick(venueId: IVenue): void;
  highlightVenueId: number | null;
}

const CITY_BOUNDS: Record<Cities, [[number, number], [number, number]]> = {
  [Cities.TLV]: [[34.743188724669636,32.04521733587602],[34.79563127532833,32.097875082939666]],
  [Cities.HAIFA]: [[34.95203231324072,32.774841854691914],[35.017264690153496,32.83980945981719]],
}

const ExploreMap: React.FC<IProps> = (props: IProps) => {
  const { city, venues, onViewBoundsChange, onVenueClick, highlightVenueId } = props;

  const mapRef = React.useRef<mapboxgl.Map>();
  const [isLoaded, setIsLoaded] = React.useState(false);

  const handleLoad = (event: MapboxEvent) => {
    const { target } = event;
    mapRef.current = target;
    setIsLoaded(true);
    // console.log(JSON.stringify(mapRef.current.getBounds().toArray()));
    // const mapboxLanguage = new MapboxLanguage({ defaultLanguage: 'fr' });
    // target.addControl(mapboxLanguage);
  };

  const handleMoveEnd = (event: ViewStateChangeEvent): void => {
    onViewBoundsChange(event.target.getBounds());
    // console.log(JSON.stringify(mapRef.current.getBounds().toArray()));
  };

  useEventListener('btdt.centerMap', (event: Event) => {
    const customEvent = event as CustomEvent<{ coordinates: number [] }>;
    const { coordinates } = customEvent.detail;
    if (mapRef.current) {
      mapRef.current.flyTo({ center: [coordinates[1], coordinates[0]], zoom: 16 });
    }
  });

  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.fitBounds(CITY_BOUNDS[city], { animate: false })
    }
  }, [city, isLoaded]);

  const maxBounds = React.useMemo<[[number, number], [number, number]]>(() => {
    const cityBounds = CITY_BOUNDS[city];
    const PAD = 0.1;
    return [[cityBounds[0][0] - PAD, cityBounds[0][1] - PAD], [cityBounds[1][0] + PAD, cityBounds[1][1] + PAD]];
  }, [city]);

  return (
    <ReactMapGl
      initialViewState={{
        bounds: CITY_BOUNDS[city],
      }}
      maxBounds={maxBounds}
      style={{ width: '100%', height: '100%' }}
      mapStyle="mapbox://styles/mapbox/dark-v11"
      mapboxAccessToken="pk.eyJ1IjoiYW1pcm96ZXIiLCJhIjoiY2xiODN3OHp5MDgzdzNvbXRzbHRmNDh6byJ9.z32MJnbhRaPU0qEXgjq7bQ"
      onLoad={handleLoad}
      onMoveEnd={handleMoveEnd}
    >
      <MemoriesGlowLayer venues={venues} />
      {venues.map((venue) => {
        const isSelected = highlightVenueId === venue.id;
        const hasStories = Boolean(config.enableMemoriesMap && venue.memories?.length);
        const defaultColor = hasStories ? '#77b5ff' : '#4499ff';

        return (
          <Marker
            key={`${venue.id}_${isSelected}`}
            longitude={venue.coordinates[1]}
            latitude={venue.coordinates[0]}
            onClick={() => onVenueClick(venue)}
            color={isSelected ? '#ccaa00' : defaultColor}
            clickTolerance={20}
            style={{ cursor: 'pointer' }}
          />
        );
      })}
    </ReactMapGl>
  )
};

export default ExploreMap;
