import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { IMemory } from 'btdt-types';
import './MemorySummary.css';

interface IProps {
  memory: IMemory;
}

function MemorySummary({ memory }: IProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <span className={`MemorySummary MemorySummary-${isExpanded ? 'expanded' : 'collapsed'}`} onClick={() => setIsExpanded(!isExpanded)}>
      <span className="MemorySummary-content">
        <FaQuoteRight />
        <span className="MemorySummary-content-text">
          {memory.content}
        </span>
        {isExpanded && <FaQuoteLeft />}
      </span>
      <small>— {memory.isAnonymous ? 'אנונימי.ת' : memory.userName}</small>
    </span>
  )
}

export default MemorySummary;
