import React from 'react';
import './SiteLogo.css';

const SiteLogo = () => (
  <div className="SiteLogo">
    <h1>מפת הזמן</h1>
    <span className="SiteLogo-beta-tag">היינו שם. בטא.</span>
  </div>
);

export default SiteLogo;
