import React from 'react';
import { IVenue } from 'btdt-types';
import venuesDataProvider from '../../../data/venuesDataProvider';
import VenuesTimeline from './VenuesTimeline';

interface IProps {
  venue: IVenue;
}

const VenuesInLocation: React.FC<IProps> = ({ venue }: IProps) => {
  const { venues: venuesInLocation } = venuesDataProvider.useSearchVenuesQuery({ city: venue.city, coordinates: venue.coordinates });

  if (!venuesInLocation?.length || venuesInLocation.length < 2) {
    return null;
  }

  return (
    <>
      <h2>באותו מקום</h2>
      <VenuesTimeline venues={venuesInLocation} currentVenueId={venue.id} />
    </>
  );
}

export default VenuesInLocation;
