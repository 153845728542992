import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import { Helmet } from 'react-helmet';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import VenueDetails from './ExploreScreen/common/VenueDetails/VenueDetails';
import NearbyList from './ExploreScreen/MobileApp/NearbyList/NearbyList';
import MobileSearchScreen from './ExploreScreen/MobileApp/MobileSearchScreen';
import VenuesIndexScreen from './admin/VenuesIndexScreen/VenuesIndexScreen';
import AdminArea from './admin/AdminArea';
import AboutScreen from './AboutScreen';
import ContributeScreen from './ContributeScreen';
import TermsOfUseScreen from './TermsOfUseScreen';
import PrivacyPolicyScreen from './PrivacyPolicy';
import config from './config';

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnMount: false, refetchOnWindowFocus: false } } });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="btdt-dev.eu.auth0.com"
      clientId="Ipadt9qVy5MsFNO3sJnViKuo6U6HlcMD"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <QueryClientProvider client={queryClient}>
        {/* @ts-ignore */}
        <Helmet defaultTitle="מפת הזמן" titleTemplate="%s | מפת הזמן" />
        <CssVarsProvider defaultMode="dark" theme={extendTheme({ fontFamily: { body: 'Open Sans Hebrew', fallback: 'sans-serif' } })}>
          <BrowserRouter>
            <Routes>
              <Route path="/admin" element={<AdminArea />}>
                <Route index element={<VenuesIndexScreen />} />
              </Route>
              <Route path="/about" element={<AboutScreen />} />
              <Route path="/terms-of-use" element={<TermsOfUseScreen />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
              <Route path="/contribute" element={<ContributeScreen />} />
              <Route path="/" element={<Root />}>
                <Route path="venues/:id" element={<VenueDetails />} />
                <Route path="nearby" element={<NearbyList />} />
                <Route path="search" element={<MobileSearchScreen />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CssVarsProvider>
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
