import React from 'react';
import SidebarHeader from '../common/SidebarHeader/SidebarHeader';
import SearchBar from '../common/SearchBar/SearchBar';
import { ExploreScreenContext } from '../context';

const MobileSearchHeader: React.FC = () => {
  const { filters: { city } } = React.useContext(ExploreScreenContext);

  return (
    <SidebarHeader>
      <SearchBar city={city} showExitIfHasValue />
    </SidebarHeader>
  );
};

export default MobileSearchHeader;
