import React from 'react';
import { Link } from 'react-router-dom';
import ContentScreen from './ContentScreen/ContentScreen';

const TermsOfUseScreen = (): React.ReactElement => (
  <ContentScreen metaTitle="תנאי שימוש">
    <h1>
        תנאי שימוש
    </h1>
    <p>
    מפת הזמן ("האתר") היא פרויקט מחקר ארכיוני ללא מטרות רווח המופעל בהתנדבות על ידי אמיר עוזר ורעות מרים כהן ("הבעלים").
    </p>
    <p>
    הגלישה באתר כפופה לתנאים המפורטים בתקנון תנאי שימוש זה ("התקנון"). אנא קראו אותו בקפידה, שכן הגלישה באתר וביצוע פעולות בו מעידים על הסכמתך לתנאים הכלולים בתקנון והוא מהווה חוזה מחייב בינך לבין הבעלים.
    </p>
    <p>
    האמור בתקנון זה בלשון זכר או נקבה הוא לשם הנוחות בלבד והתקנון מתייחס לכל המגדרים באופן שווה.
    </p>
    <h2>
    תוכן האתר
    </h2>
    <p>
    האתר מכיל מידע ציבורי על בתי עסק בערים תל אביב וחיפה בעבר ובהווה. המידע המופיע באתר מתעדכן באופן שוטף ונאסף ממקורות פומביים שונים דוגמת ארכיוני עיתונות ורשת לצד תרומות ידע אישי מגופים ואנשים פרטיים שעבר אימות לשביעות רצונם של הבעלים. אנו עושים את מירב המאמצים לספק באתר מידע מהימן עד כמה שניתן, אך כטבעו של מידע ארכיוני יהיו מקרים שבהם הוא אינו מדויק. איננו מציעים ערבויות כלשהן באשר למהימנות, נכונות ומלאות המידע המופיע.
    </p>
    <p>
    המשתמשים באתר יכולים ליצור לעצמם פרופיל משתמש אישי, דרכו אפשר לתרום סיפורים, חוויות ותמונות מבתי עסק המופיעים על המפה.
    </p>
    <p>
    התכנים עוברים סינון על ידי הבעלים, עשויים לעבור עריכה מינימלית ומוצגים עם או בלי קרדיט לבחירתו של התורם. הבעלים אינם אחראים על אימות התוכן של תרומות אלה וכל טענה בנוגע לתוכן תטופל מול האדם שתרם אותו.
    </p>
    <p>
    משתמשים שרוצים לתרום תמונות מבתי עסק מתחייבים שהם בעלי זכויות היוצרים באותן תמונות או יספקו אישור שימוש מבעלי זכויות היוצרים. התמונות יפורסמו בליווי הקרדיט המתאים ואין בפרסומן באתר משום ויתור על זכויות היוצרים בהן.
    </p>
    <p>
    תיקוני ותוספות מידע טכני על בתי עסק לא יופיעו עם קרדיט למעט במקרים יוצאי דופן לשיקול דעת הבעלים.
    </p>
    <h2>
      מדיניות פרטיות
    </h2>
    <p>
      <Link to="/privacy-policy">ראו מסמך מורחב לפרטים על איזה מידע אנו אוספים ולאיזה שימוש</Link>
    </p>
    <p>
    במועד ההרשמה לפרופיל משתמש באתר ניתן לבחור לאפשר או לסרב לקבלת מיילים מצוות האתר. מיילים אלה יכללו הודעות מכל סוג בקשר לפעילות האתר ויישלחו בהתאם לשיקול הדעת של הבעלים.
    </p>
    <p>
    הבעלים לא יעבירו את פרטי המשתמשים הרשומים לאף צד ג' ולעולם לא יעשו שימוש בפרטים האישיים או במידע שנתרם על יד המשתמשים למטרות מסחריות כלשהן.
    </p>
    <p>
    תכני האתר הפומביים או חלקים מהם עשויים להופיע מעת לעת במיזמים ציבוריים ואירועים קהילתיים שונים ללא מטרות רווח.
    </p>
    <p>
    הבעלים נוקטים באמצעי זהירות מקובלים על מנת לשמור, ככל האפשר, על סודיות המידע הפרטי של המשתמשים. במקרים שאינם בשליטת הבעלים ו\או נובעים מכוח עליון, לא יהיו הבעלים אחראים לכל נזק מכל סוג שהוא, ישיר או עקיף, שייגרם למשתמש, אם מידע זה יאבד ו\או יעשה בו שימוש לא מורשה.
    </p>
    <h2>
    דין וסמכות שיפוט
    </h2>
    <p>
    פרשנותו ואכיפתו של תקנון זה ו\או כל פעולה או סכסוך הנובע ממנו יעשו בהתאם לדין הישראלי בלבד ולבית המשפט המוסמך באחד מהמחוזות תל אביב-יפו או חיפה תהא מסורה סמכות השיפוט הבלעדית.
    </p>
    <h2>
    קניין רוחני
    </h2>
    <p>
    כל המידע הטקסטואלי המופיע באתר נתון תחת כללי השימוש ההוגן כמפורט בסעיף 19 לחוק זכות יוצרים, התשס"ח-2007.
    </p>
    <p>
    התורמים תוכן ומידע לאתר עושים זאת בידיעה כי יתכן ויעשה בו שימוש ציבורי ופומבי מסוגים שונים ללא מטרות רווח, תוך שמירה על קרדיט עבורם במקרים הרלוונטיים ובמידה ולא ביקשו להסירו.
    </p>
    <p>
    צילומים המופיעים באתר מפורסמים תחת שימוש מורשה או שימוש הוגן וכל שימוש חוזר בהם על ידי צד ג' מותנה בקבלת רישיון מתאים בכפוף לחוק.
    </p>
    <p>
    האתר מיועד לספק מידע לשימוש לא-מסחרי בלבד. אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת במידע כלשהו מהאתר למטרות מסחריות.
    </p>
    <p>
    אין להשתמש באתר זה או בכל מידע המופיע בו לכל מטרה מסחרית, לרבות (אך לא רק) פרסום או קידום או יצירת פעילות באתר אינטרנט אחר.
    </p>
    {/*<h2>*/}
    {/*יצירת קשר*/}
    {/*</h2>*/}
    {/*<p>*/}
    {/*בכל שאלה או בירור לגבי האתר ותכניו, וכן בכל פנייה אחרת בעניין פגיעה בזכות או תוכן פוגע, יש ליצור קשר בכתובת המייל ותיענו בהקדם.*/}
    {/*</p>*/}
  </ContentScreen>
);

export default TermsOfUseScreen;
