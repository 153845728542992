import React from 'react';
import { IVenue } from 'btdt-types';
import useVenuesIndex from '../../useVenuesIndex';
import VenueRelationAdd from './VenueRelationAdd';
import { FaTrashAlt } from 'react-icons/fa';
import { useDeleteRelation } from './apiHooks';

interface IProps {
  venue: IVenue;
}

function VenueRelations({ venue }: IProps): React.ReactElement | null {
  const { venues: allVenues } = useVenuesIndex();

  const { deleteRelation } = useDeleteRelation();

  const relatedVenues = React.useMemo(() => {
    return allVenues.filter((optionalVenue) => venue?.relatedVenues?.includes(optionalVenue.id));
  }, [allVenues, venue]);

  const requestDelete = (relatedVenueId: number): void => {
    // eslint-disable-next-line
    if (confirm('באמת למחוק את הקישור?')) {
      deleteRelation({ venue1Id: venue.id, venue2Id: relatedVenueId });
    }
  };

  return (
    <div>
      <table>
        <tbody>
          {relatedVenues.map((relatedVenue) => (
            <tr key={relatedVenue.id}>
              <td>{relatedVenue.name || relatedVenue.nameEn} <small>{relatedVenue.address}</small></td>
              <td>
                <button type="button" onClick={() => requestDelete(relatedVenue.id)} title="מחיקה">
                  <FaTrashAlt/>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <VenueRelationAdd venue={venue}/>
    </div>
  )
}

export default VenueRelations;
