import './TagsFilter.css';

import React from 'react';
import { VenueTags } from 'btdt-types';
import { TagLabels, TagLabelsPlural } from '../../../../consts';

interface IProps {
  value: VenueTags[];
  onChange(newTags: VenueTags[]): void;
}

const TagsFilter: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;

  return (
    <div className="TagsFilter">
      {Object.values(VenueTags).map((tagOption) => (
        <label key={tagOption} className={value.includes(tagOption) ? 'selected' : (value.length === 0 ? 'selected-by-default' : '')}>
          <input
            type="checkbox"
            checked={value.includes(tagOption)}
            onChange={(event) => {
              if (event.target.checked) {
                onChange([...value, tagOption])
              } else {
                onChange(value.filter((option) => option !== tagOption));
              }
            }}
          />
          {TagLabelsPlural[tagOption] || TagLabels[tagOption]}
        </label>
      ))}
    </div>
  )
};

export default TagsFilter;
