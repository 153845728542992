import './YearRangeFilter.css';

import React from 'react';
import { IYearRange } from '../../../types';
import ReactSlider from 'react-slider';

interface IProps {
  value: IYearRange | null;
  onChange(newRange: IYearRange | null): void;
}

const currentYear = new Date().getFullYear();

const YearRangeFilter: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;

  const handleAllYearsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    if (checked) {
      onChange(null);
      return;
    }
    onChange({ from: currentYear, to: currentYear });
  }

  return (
    <div className="YearRangeFilter">
      <label className={`YearRangeFilter-all-years-toggle ${value === null ? 'active' : ''}`}>
        <input
          type="checkbox"
          checked={value === null}
          onChange={handleAllYearsCheckboxChange}
        />
        כל השנים
      </label>
      {/* @ts-ignore */}
      <ReactSlider
        value={value ? value.from : currentYear}
        onChange={(newValue) => {
          onChange({ from: newValue, to: newValue });
        }}
        min={currentYear - 100}
        max={currentYear}
        className={`YearRangeFilter-slider ${value ? '' : 'all-years'}`}
        thumbClassName="YearRangeFilter-slider-thumb"
        trackClassName="YearRangeFilter-slider-track"
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      />
    </div>
  );
};

export default YearRangeFilter;
