import React from 'react';
import { Cities, IVenue } from 'btdt-types';
import { IExploreFilters } from './types';
import venuesDataProvider from '../data/venuesDataProvider';
import { useParams } from 'react-router-dom';

const resolveInitialFilters = (): IExploreFilters => {
  const randomYear = Math.floor(Math.random() * 10) + 2005;

  const savedCity = localStorage.getItem('city');
  let city: Cities;
  if (savedCity && (savedCity === Cities.HAIFA || savedCity === Cities.TLV)) {
    city = savedCity;
  } else {
    city = Cities.TLV;
  }

  return {
    city,
    yearRange: { from: randomYear, to: randomYear },
    tags: [],
  }
}

export interface IUseExploreResult {
  filters: IExploreFilters;
  applyFilters(filters: Partial<IExploreFilters>): void;
  venues: IVenue[];
}

const useExplore = (): IUseExploreResult => {
  const { id } = useParams();

  const [filters, setFilters] = React.useState<IExploreFilters>(resolveInitialFilters());

  const { venues } = venuesDataProvider.useSearchVenuesQuery(filters, id ? parseInt(id) : null);

  const applyFilters = (newFilters: Partial<IExploreFilters>): void => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    if (newFilters.city) {
      localStorage.setItem('city', newFilters.city);
    }
  }

  return {
    filters,
    applyFilters,
    venues: venues || [],
  };
};

export default useExplore;
