import React from 'react';
import { IVenue } from 'btdt-types';
import venuesDataProvider from '../../../data/venuesDataProvider';
import VenuesTimeline from './VenuesTimeline';

interface IProps {
  venue: IVenue;
}

function RelatedVenues({ venue }: IProps): React.ReactElement | null {
  const { venues: allVenues } = venuesDataProvider.useSearchVenuesQuery({ city: venue.city });

  const relatedVenues = React.useMemo(() => {
    return allVenues.filter((optionalVenue) => optionalVenue.id === venue.id || venue.relatedVenues?.includes(optionalVenue.id));
  }, [allVenues]);

  if (relatedVenues.length <= 1) {
    return null;
  }

  return (
    <>
      <h2>גלגול של</h2>
      <VenuesTimeline venues={relatedVenues} currentVenueId={venue.id} showAddress />
    </>
  )
}

export default RelatedVenues;
