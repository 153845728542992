import React from 'react';
import sortBy from 'lodash/sortBy';
import { IVenue } from 'btdt-types';
import ResultsListItem from './ResultsListItem';

interface IProps {
  venues: (IVenue & { distance?: number })[];
  highlightMemories?: boolean;
}

const ResultsList: React.FC<IProps> = ({ venues, highlightMemories }: IProps) => {
  const sortedVenues = React.useMemo(() => {
    if (highlightMemories) {
      return sortBy(venues, (venue) => !(venue.memories && venue.memories.length > 0))
    }
    return venues;
  }, [venues, highlightMemories]);

  return (
    <div>
      {sortedVenues.map((venue) => (
        <ResultsListItem key={venue.id} venue={venue} />
      ))}
    </div>
  );
}

export default ResultsList;
