import './ExploreMapContainer.css';

import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Cities, IVenue } from 'btdt-types';
import ExploreMap from './ExploreMap/ExploreMap';
import SiteLogo from './SiteLogo/SiteLogo';
import CityFilter from '../CityFilter/CityFilter';
import FiltersBar from './FiltersBar/FiltersBar';
import { ExploreScreenContext } from '../context';
import { NavigationContext, NavigationFlows } from '../../context';
import { Events, trackEvent } from '../../utils/analytics';
import { getVenueUrl } from '../../helpers/navigation';
import { useDevices } from '../../helpers/devices';
import { VenuePreviewPane } from './VenuePreviewPane/VenuePreviewPane';

const ENABLE_MOBILE_PREVIEWS = false;

const ExploreMapContainer: React.FC = () => {
  const { setFlow } = React.useContext(NavigationContext);
  const { filters, applyFilters, venues } = React.useContext(ExploreScreenContext);
  const [mobilePreviewedVenueId, setMobilePreviewedVenueId] = React.useState<number | null>(null);

  const { isMobile } = useDevices();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: idRouteParam } = useParams();

  const highlightVenueId = React.useMemo(() => {
    if (isMobile && ENABLE_MOBILE_PREVIEWS) {
      return mobilePreviewedVenueId;
    }
    return idRouteParam ? parseInt(idRouteParam) : null;
  }, [isMobile, mobilePreviewedVenueId, idRouteParam]);

  React.useEffect(() => {
    setFlow(NavigationFlows.EXPLORE);
  }, []);

  const handleCityChange = (city: Cities): void => {
    applyFilters({ ...filters, city });
    setMobilePreviewedVenueId(null);
    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  const handleVenueClick = (venue: IVenue): void => {
    trackEvent(Events.CLICK_EXPLORE_MAP_MARKER);
    if (isMobile && ENABLE_MOBILE_PREVIEWS) {
      setMobilePreviewedVenueId(venue.id);
    } else {
      navigate(getVenueUrl(venue));
    }
  };

  return (
    <div className="ExploreMapContainer">
      <ExploreMap
        city={filters.city}
        venues={venues}
        onViewBoundsChange={(bounds) => applyFilters({ ...filters, bounds })}
        onVenueClick={handleVenueClick}
        highlightVenueId={highlightVenueId}
      />
      <div className="ExploreMapContainer-city-filter-container">
        <SiteLogo />
        <CityFilter value={filters.city} onChange={handleCityChange} />
      </div>
      <div className="ExploreMapContainer-filters-bar-container">
        <FiltersBar filters={filters} applyFilters={applyFilters} />
      </div>
      {mobilePreviewedVenueId && ENABLE_MOBILE_PREVIEWS && <VenuePreviewPane venueId={mobilePreviewedVenueId} />}
    </div>
  );
};

export default ExploreMapContainer;
