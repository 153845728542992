import { useMutation, useQueryClient } from 'react-query';
import useApiClient from '../../../useApiClient';

export function useAddRelation() {
  const queryClient = useQueryClient();
  const { getApiClient } = useApiClient();

  const { mutate: addRelation } = useMutation(
    async ({ venue1Id, venue2Id }: { venue1Id: number, venue2Id: number }): Promise<void> => {
      const apiClient = await getApiClient();
      await apiClient.post(`/api/venueRelation`, { venue1Id, venue2Id });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-venues');
      },
    },
  );

  return { addRelation };
}

export function useDeleteRelation() {
  const queryClient = useQueryClient();
  const { getApiClient } = useApiClient();

  const { mutate: deleteRelation } = useMutation(
    async ({ venue1Id, venue2Id }: { venue1Id: number, venue2Id: number }): Promise<void> => {
      const apiClient = await getApiClient();
      await apiClient.post(`/api/venueRelation/delete`, { venue1Id, venue2Id });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-venues');
      },
    },
  );

  return { deleteRelation };
}
