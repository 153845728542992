import React from 'react';
import ContentScreen from './ContentScreen/ContentScreen';

const ContributeScreen = () => (
  <ContentScreen metaTitle="תוספות ותיקונים">
    <h1>תוספות ותיקונים</h1>
    <p>נשמח לקבל כל מידע נוסף שתרצו לתרום לנו.<br/>נעדכן את המידע באתר בכפוף ליכולת לאמת אותו ברמה מספקת מבחינתנו, אז כל לינק לסימוכין יתקבל באהבה.</p>
    <iframe
      title="טופס שליחת תוספות ותיקונים"
      src="https://docs.google.com/forms/d/e/1FAIpQLSfUVqJHD8q_vPBaqTLbj6T5WtdOP3IJCyWD8u3EGe5dXmWk2w/viewform?embedded=true"
      width="100%"
      height="2200"
      frameBorder={0}
      marginHeight={0}
      marginWidth={0}
    >
      בטעינה…
    </iframe>
  </ContentScreen>
);

export default ContributeScreen;
