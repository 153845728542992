import './ExploreSidebarHeader.css';

import React from 'react';
import SidebarHeader from '../../common/SidebarHeader/SidebarHeader';
import SearchButton from './SearchButton/SearchButton';
import { NavigationContext, NavigationFlows } from '../../../context';

const ExploreSidebarHeader: React.FC = () => {
  const { setFlow } = React.useContext(NavigationContext);

  return (
    <SidebarHeader>
      <div className="ExploreSidebarHeader">
        <h2>מקומות מהמפה</h2>
        <SearchButton onClick={() => setFlow(NavigationFlows.SEARCH)} />
      </div>
    </SidebarHeader>
  );
}

export default ExploreSidebarHeader;
