import './MobileApp.css';

import React from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { NavigationContext, NavigationFlows } from '../../context';
import MobileLayout from './MobileLayout/MobileLayout';
import useExplore from '../useExplore';
import ExploreMapContainer from '../ExploreMapContainer/ExploreMapContainer';
import { ExploreScreenContext } from '../context';
import MobileBackToExploreHeader from './MobileBackToExploreHeader';
import NearbyHeader from './NearbyHeader';
import MobileSearchHeader from './MobileSearchHeader';

const MobileApp: React.FC = () => {
  const { flow } = React.useContext(NavigationContext);

  const { filters, applyFilters, venues } = useExplore();

  const children = useOutlet();
  const { pathname } = useLocation();

  const isInVenueDetails = pathname.includes('venues/');

  return (
    <ExploreScreenContext.Provider value={{ filters, applyFilters, venues }}>
      {/* @ts-ignore */}
      <MobileLayout>
        {flow === NavigationFlows.EXPLORE && (
          <>
            <ExploreMapContainer />
            {isInVenueDetails ? (
              // @ts-ignore
              <div className="MobileApp-explore-overlay">
                <MobileBackToExploreHeader />
                {children}
              </div>
            ) : null}
          </>
        )}
        {flow === NavigationFlows.NEARBY && <NearbyHeader showBack={isInVenueDetails} />}
        {flow === NavigationFlows.SEARCH && <MobileSearchHeader />}
        {flow !== NavigationFlows.EXPLORE && children}
      </MobileLayout>
    </ExploreScreenContext.Provider>
  );
};

export default MobileApp;
