import './DesktopApp.css';

import React from 'react';
import { useOutlet } from 'react-router-dom';
import useExplore from '../useExplore';
import ResultsList from '../common/ResultsList/ResultsList';
import SidebarFooter from '../SidebarFooter/SidebarFooter';
import { ExploreScreenContext } from '../context';
import BackToExploreHeader from './BackToExploreHeader';
import { NavigationContext, NavigationFlows } from '../../context';
import ExploreSidebarHeader from './ExploreSidebarHeader/ExploreSidebarHeader';
import SearchHeader from './SearchHeader';
import ExploreMapContainer from '../ExploreMapContainer/ExploreMapContainer';
import config from '../../config';

const DesktopApp: React.FC = () => {
  const { filters, applyFilters, venues } = useExplore();
  const { flow } = React.useContext(NavigationContext);

  const children = useOutlet();

  return (
    <ExploreScreenContext.Provider value={{ filters, applyFilters, venues }}>
      <div className="DesktopApp">
        <ExploreMapContainer />
        <div className="DesktopApp-sidebar">
          {flow === NavigationFlows.EXPLORE && (children ? <BackToExploreHeader /> : <ExploreSidebarHeader />)}
          {flow === NavigationFlows.SEARCH && <SearchHeader />}
          {children || <ResultsList venues={venues} highlightMemories={config.enableMemoriesMap} />}
          <SidebarFooter />
        </div>
      </div>
    </ExploreScreenContext.Provider>
  );
}

export default DesktopApp;
