import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IMemory } from 'btdt-types';
import config from '../config';

const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
});

export const useCreateMemoryMutation = ({ onSuccess }: { onSuccess(): void }) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('all-venues');
      onSuccess();
    },
    mutationFn: (newMemory: Partial<IMemory>) => apiClient.post('/api/memory', newMemory),
  });
};
