import React from 'react';
import MediaQuery from 'react-responsive';
import MobileApp from './ExploreScreen/MobileApp/MobileApp';
import DesktopApp from './ExploreScreen/DesktopApp/DesktopApp';
import { DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from './helpers/devices';
import { NavigationContext, NavigationFlows } from './context';

const Root: React.FC = () => {
  const [flow, setFlow] = React.useState<NavigationFlows>(NavigationFlows.EXPLORE);

  return (
    <NavigationContext.Provider value={{ flow, setFlow }}>
      <MediaQuery minWidth={DESKTOP_MIN_WIDTH}>
        <DesktopApp />
      </MediaQuery>
      <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
        <MobileApp />
      </MediaQuery>
    </NavigationContext.Provider>
  );
}

export default Root;
