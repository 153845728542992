import React from 'react';
import VenueSelect from './VenueSelect';
import { useAddRelation } from './apiHooks';
import { IVenue } from 'btdt-types';

interface IProps {
  venue: IVenue;
}

function VenueRelationAdd({ venue }: IProps): React.ReactElement {
  const { addRelation } = useAddRelation();

  const handleVenueSelection = (newRelatedVenueId: number): void => {
    if (newRelatedVenueId) {
      addRelation({ venue1Id: venue.id, venue2Id: newRelatedVenueId })
    }
  };

  return (
    <div>
      <VenueSelect onChange={handleVenueSelection} excludeOptions={[venue.id, ...(venue.relatedVenues || [])]} />
    </div>
  )
}

export default VenueRelationAdd;
