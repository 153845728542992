import React from 'react';
import SearchBar from '../common/SearchBar/SearchBar';
import SidebarHeader from '../common/SidebarHeader/SidebarHeader';
import { NavigationContext, NavigationFlows } from '../../context';
import { ExploreScreenContext } from '../context';

const SearchHeader: React.FC = () => {
  const { filters: { city } } = React.useContext(ExploreScreenContext);
  const { setFlow } = React.useContext(NavigationContext);

  return (
    <SidebarHeader>
      <SearchBar onExit={() => setFlow(NavigationFlows.EXPLORE)} city={city} showExit />
    </SidebarHeader>
  );
}

export default SearchHeader;
