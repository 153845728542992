import React from 'react';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import './SidebarFooter.css';

const SidebarFooter = () => (
  <div className="SidebarFooter">
    <Link to="/about">
      <FaInfoCircle />
      <span>
לאן הגעתי ואיך אפשר לעזור?
      </span>
    </Link>
  </div>
);

export default SidebarFooter;
