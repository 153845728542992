import React, { ChangeEvent, FormEvent } from 'react';
import Textarea from '@mui/joy/Textarea';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import { FaPaperPlane } from 'react-icons/fa';
import { IMemory, IVenue } from 'btdt-types';
import './NewMemoryForm.css';
import { useCreateMemoryMutation } from './hooks';

interface IProps {
  venue: IVenue;
  onDone(): void;
}

function NewMemoryForm({ venue, onDone }: IProps): React.ReactElement {
  const [memory, setMemory] = React.useState<Partial<IMemory>>({
    venueId: venue.id,
    content: sessionStorage.getItem(`memoryContent_${venue.id}`) || '',
    userName: sessionStorage.getItem('userName') || '',
    userEmail: sessionStorage.getItem('userEmail') || '',
    isAnonymous: false,
  });

  const { isLoading: isSubmitting, mutate } = useCreateMemoryMutation({
    onSuccess: () => {
      sessionStorage.removeItem(`memoryContent_${venue.id}`);
      onDone();
    },
  });

  const handleSubmit = React.useCallback((event: FormEvent) => {
    event.preventDefault();
    if (!memory.content) {
      alert('נשמח לכמה מילים');
      return;
    }
    if (!memory.isAnonymous && !memory.userName) {
      alert('נשמח לדעת את השם שלך. אפשר גם לבחור לפרסם באנונימיות')
      return;
    }
    mutate(memory);
  }, [memory]);

  const handleContentChange = React.useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const content = event.target.value;
    sessionStorage.setItem(`memoryContent_${venue.id}`, content);
    setMemory((memory) => ({ ...memory, content }));
  }, []);

  const handleUserNameChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const userName = event.target.value;
    sessionStorage.setItem('userName', userName);
    setMemory((memory) => ({ ...memory, userName }));
  }, []);

  const handleUserEmailChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const userEmail = event.target.value;
    sessionStorage.setItem('userEmail', userEmail);
    setMemory((memory) => ({ ...memory, userEmail }));
  }, []);

  const handleIsAnonymousChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMemory((memory) => ({ ...memory, isAnonymous: event.target.checked }));
  }, []);

  return (
    <div className="NewMemoryForm">
      <h2>שיתוף זיכרון</h2>
      <form onSubmit={handleSubmit}>
        <Textarea
          value={memory.content}
          onChange={handleContentChange}
          placeholder={`הזיכרון שלי מ"${venue.name || venue.nameEn}"`}
          minRows={3}
          maxRows={10}
          size="md"
          autoFocus
        />
        <h3>הפרטים שלך</h3>
        <p><small>לא חובה, אבל בעתיד נוכל לקשר לחשבון שלך</small></p>
        <Input
          value={memory.userName}
          onChange={handleUserNameChange}
          placeholder="השם שלך"
          size="sm"
          autoComplete="name"
        />
        <div dir={memory.userEmail ? 'ltr' : 'rtl'}>
          <Input
            type="email"
            value={memory.userEmail}
            onChange={handleUserEmailChange}
            placeholder="דואר אלקטרוני"
            size="sm"
            autoComplete="email"
          />
        </div>
        <div className="NewMemoryForm-is-anonymous">
          <label>
            <input type="checkbox"
                   checked={memory.isAnonymous}
                   onChange={handleIsAnonymousChange}
            />
            פרסמו ללא השם שלי
          </label>
          <small>אפשר לתת פרטים ועדיין לפרסם אנונימית</small>
        </div>
        <Button type="submit" startDecorator={<FaPaperPlane />} disabled={isSubmitting}>פרסום</Button>
      </form>
    </div>
  )
}

export default NewMemoryForm;
