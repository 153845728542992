import React from 'react';
import { IVenue } from 'btdt-types';
import { IVenuesIndexFilters } from '../types';
import VenueTableRowView from './VenueTableRowView';
import VenueTableRowCreate from './VenueTableRowCreate';
import './VenuesTable.css';

interface IProps {
  venues: IVenue[];
  filters: IVenuesIndexFilters;
  applyFilters(filters: Partial<IVenuesIndexFilters>): void;
  onUpdated(updatedVenue: IVenue): void;
  onCreated(createdVenue: IVenue): void;
}

const VenuesTable: React.FC<IProps> = ({ venues, filters, applyFilters, onUpdated, onCreated }: IProps) => {
  const [mainSelectedId, setMainSelectedId] = React.useState<number>();

  const [isCreating, setIsCreating] = React.useState(false);

  return (
    <table className="venuesTable">
      <colgroup>
        <col width="180"/>
        <col width="160"/>
        <col width="160"/>
        <col width="78"/>
        <col width="30"/>
        <col width="78"/>
        <col width="170"/>
        <col/>
        <col width="92"/>
        <col width="92"/>
        <col width="92"/>
        <col width="92"/>
      </colgroup>
      <thead>
        <tr>
          <th colSpan={2}>
            שם
            <br/>
            <input
              type="text"
              value={filters.name || ''}
              onChange={(event) => applyFilters({ name: event.target.value })}
              dir={filters.name ? 'auto' : 'rtl'}
              placeholder="חיפוש..."
            />
          </th>
          <th>
            כתובת
            <br/>
            <input
              type="text"
              value={filters.address || ''}
              onChange={(event) => applyFilters({ address: event.target.value })}
              placeholder="חיפוש..."
            />
          </th>
          <th colSpan={3}>שנים</th>
          <th>
            תגיות
          </th>
          <th>
            תיאור
          </th>
          <th>
            בעלות
          </th>
          <th>
            מקורות
          </th>
          <th/>
          <th>
            קשורים
          </th>
        </tr>
      </thead>
      <tbody>
      {venues.map((venue) => (
        <VenueTableRowView
          key={venue.id}
          venue={venue}
          onUpdated={onUpdated}
          onSelected={() => setMainSelectedId(venue.id)}
          isMainSelected={mainSelectedId === venue.id}
        />
      ))}
      {filters.name && !isCreating && (
        <tr>
          <td colSpan={11}>
            <button type="button" onClick={() => setIsCreating(true)}>הוספת "{filters.name}"</button>
          </td>
        </tr>
      )}
      {isCreating && (
        <>
          <tr>
            <th colSpan={10}>
              מקום חדש-ישן
            </th>
            <th>
              <button
                type="button"
                onClick={() => {
                  if (window.confirm('לבטל?')) {
                    setIsCreating(false);
                  }
                }}
              >
                ביטול
              </button>
            </th>

          </tr>
          <VenueTableRowCreate
            initialVenue={{
              name: filters.name || '',
              city: filters.city,
            }}
            autoFocus="name"
            onCreated={(createdVenue) => { onCreated(createdVenue); setIsCreating(false); }}
          />
        </>
      )}
      </tbody>
    </table>
  )
};


export default VenuesTable;
