import './SearchBar.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { components, InputActionMeta, SingleValue } from 'react-select';
import { MdClose } from 'react-icons/md';
import { Cities } from 'btdt-types';
import venuesDataProvider from '../../../data/venuesDataProvider';
import { getVenueUrl } from '../../../helpers/navigation';
import useDebounce from '../../../helpers/useDebounce';
import { Events, trackEvent } from '../../../utils/analytics';

interface IProps {
  city: Cities;
  onExit?(): void;
  showExit?: boolean;
  showExitIfHasValue?: boolean;
}

const CONTRIBUTE_OPTION_ID = -1;

const SearchBar: React.FC<IProps> = ({ city, onExit, showExit, showExitIfHasValue }: IProps) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [notFoundQuery, setNotFoundQuery] = React.useState('');
  const { venues: results } = venuesDataProvider.useSearchVenuesQuery(
    { city, name: searchQuery },
    null,
    { enabled: !!searchQuery },
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);

  React.useEffect(() => {
    if (!debouncedSearchQuery) return;
    trackEvent(Events.SEARCH, { query: debouncedSearchQuery });
  }, [debouncedSearchQuery]);

  React.useEffect(() => {
    if (!searchQuery) return;
    if (results.length === 0) {
      setNotFoundQuery(searchQuery);
    }
  }, [results, searchQuery]);

  React.useEffect(() => {
    if (!notFoundQuery) return;
    trackEvent(Events.SEARCH_NOT_FOUND, { query: notFoundQuery });
  }, [notFoundQuery])

  const navigate = useNavigate();

  const handleInputChange = (newSearchQuery: string, { action, prevInputValue }: InputActionMeta): string => {
    if (action === 'input-change') {
      setSearchQuery(newSearchQuery);
      return newSearchQuery;
    }
    return prevInputValue;
  };

  const handleChange = (newValue: SingleValue<{ value: number }>): void => {
    if (!newValue) {
      return;
    }
    if (newValue.value === CONTRIBUTE_OPTION_ID) {
      trackEvent(Events.CLICK_LINK_TO_CONTRIBUTE, { component: 'search_bar_not_found_option' })
      navigate('/contribute');
      return;
    }
    if (!results) {
      return;
    }
    const venue = results.find((result) => result.id === newValue.value);
    if (!venue) {
      return;
    }
    trackEvent(Events.CLICK_SEARCH_RESULT, { city: venue.city, venue_id: venue.id, venue_name: venue.name });
    navigate(getVenueUrl(venue));
  }

  const options = React.useMemo(() => {
    if (!results) return [];
    if (searchQuery && results.length === 0) {
      return [{
        value: CONTRIBUTE_OPTION_ID,
        label: searchQuery,
      }];
    }
    return results.map((result) => ({
      value: result.id,
      label: `${(/[\u0590-\u05FF]/.test(searchQuery) || !result.nameEn) ? result.name : result.nameEn}, ${result.address}`
    }));
  }, [results, searchQuery]);

  const handleExitClick = (): void => {
    setSearchQuery('');
    if (onExit) {
      onExit();
    }
  };

  return (
    <div className="SearchBar">
      <Select
        id="query"
        name="query"
        onInputChange={handleInputChange}
        autoFocus
        classNamePrefix="SearchBar"
        options={options}
        value={{ value: 0, label: searchQuery }}
        inputValue={searchQuery}
        onChange={handleChange}
        placeholder="נו, קראו לזה..."
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            background: '#222222',
            border: 'none',
            borderColor: 'transparent',
            borderRadius: '18px',
            outline: 'none',
            minHeight: '36px',
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            background: '#333333',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            background: state.isFocused ? '#444444' : '#333333',
            color: 'white',
          })
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Option: (props) => {
            const { data, innerRef, innerProps } = props;
            if (data?.value === CONTRIBUTE_OPTION_ID) {
              return (
                <div ref={innerRef} {...innerProps} className="SearchBar-not-found-option">
                  נראה שאין לנו מידע על
                  "{data?.label}".
                  <br/>
                  <u>
                    אפשר לשלוח לנו כאן
                  </u>
                </div>
              )
            }
            return <components.Option {...props} />;
          },
          NoOptionsMessage: () => null,
          Menu: (props) => {
            if (props.options.length === 0) {
              return null;
            }
            return <components.Menu {...props} />;
          },
        }}
        filterOption={() => true}
      />
      {(showExit || (showExitIfHasValue && searchQuery)) && (
        <button
          type="button"
          aria-label="סגירת חיפוש"
          className="SearchBar-close-button"
          onClick={handleExitClick}
        >
          <MdClose color="white" />
        </button>
      )}
    </div>
  )
};

export default SearchBar;
